export const medium = {
  success: true,
  payload: {
    user: {
      userId: "9a619946f362",
      name: "Anurag Bhattacharjee",
      username: "anuragbhattacharjee",
      createdAt: 1455536048517,
      imageId: "0*2FAy25grUtBNGwtK.jpg",
      backgroundImageId: "",
      bio: "",
      twitterScreenName: "AnuragOn2",
      allowNotes: 1,
      mediumMemberAt: 0,
      isWriterProgramEnrolled: true,
      isSuspended: false,
      isMembershipTrialEligible: true,
      optInToIceland: false,
      type: "User",
    },
    streamItems: [
      {
        createdAt: 1595943670350,
        heading: {
          text: "Latest",
          heading: {
            fallbackTitle: "Latest",
            headingBasic: { title: "Latest" },
            headingType: "headingBasic",
          },
        },
        randomId: "80a04e87e703",
        itemType: "heading",
        type: "StreamItem",
      },
      {
        createdAt: 1595943670350,
        postPreview: {
          postId: "fec831d30d1b",
          postSuggestionReasons: [{ reason: 28 }],
        },
        randomId: "b94f57aac24b",
        itemType: "postPreview",
        type: "StreamItem",
      },
      {
        createdAt: 1595943670350,
        postPreview: {
          postId: "ef66e9c942b7",
          postSuggestionReasons: [{ reason: 28 }],
        },
        randomId: "ff6747ba1958",
        itemType: "postPreview",
        type: "StreamItem",
      },
      {
        createdAt: 1595943670350,
        postPreview: {
          postId: "309d63cfbcba",
          postSuggestionReasons: [{ reason: 28 }],
        },
        randomId: "e6e5b10bc335",
        itemType: "postPreview",
        type: "StreamItem",
      },
      {
        createdAt: 1595943670350,
        postPreview: {
          postId: "976f98f72f2e",
          postSuggestionReasons: [{ reason: 28 }],
        },
        randomId: "845057bcb74f",
        itemType: "postPreview",
        type: "StreamItem",
      },
      {
        createdAt: 1595943670350,
        postPreview: {
          postId: "22ca582493f6",
          postSuggestionReasons: [{ reason: 28 }],
        },
        randomId: "869379accaf1",
        itemType: "postPreview",
        type: "StreamItem",
      },
      {
        createdAt: 1595943670350,
        postPreview: {
          postId: "c1b794fac59b",
          postSuggestionReasons: [{ reason: 28 }],
        },
        randomId: "e3cc6862ae8f",
        itemType: "postPreview",
        type: "StreamItem",
      },
      {
        createdAt: 1595943670350,
        postPreview: {
          postId: "df579d691a8d",
          postSuggestionReasons: [{ reason: 28 }],
        },
        randomId: "35c0c38da4ac",
        itemType: "postPreview",
        type: "StreamItem",
      },
      {
        createdAt: 1595943670350,
        postPreview: {
          postId: "6346c8cb37eb",
          postSuggestionReasons: [{ reason: 28 }],
        },
        randomId: "1d64b184feb1",
        itemType: "postPreview",
        type: "StreamItem",
      },
      {
        createdAt: 1595943670350,
        postPreview: {
          postId: "555b765f2645",
          postSuggestionReasons: [{ reason: 28 }],
        },
        randomId: "b4a3d265f4a6",
        itemType: "postPreview",
        type: "StreamItem",
      },
      {
        createdAt: 1595943670350,
        postPreview: {
          postId: "ee1d219b26f4",
          postSuggestionReasons: [{ reason: 28 }],
        },
        randomId: "cf14e6d48565",
        itemType: "postPreview",
        type: "StreamItem",
      },
    ],
    userMeta: {
      numberOfPostsPublished: 30,
      userId: "9a619946f362",
      userSuggestionReason: {
        followeesWhoFollow: { users: [] },
        reason: "followeesWhoFollow",
      },
      collectionIds: [],
      authorTags: [
        {
          slug: "education",
          name: "Education",
          postCount: 307711,
          metadata: {
            postCount: 307711,
            coverImage: { id: "1*dVmAyTviHMkGJq46uvrLcg.jpeg" },
          },
          type: "Tag",
        },
        {
          slug: "technology",
          name: "Technology",
          postCount: 297732,
          metadata: {
            postCount: 297732,
            coverImage: { id: "1*yH2cmH1uhoFpR7HIseOAsw.jpeg" },
          },
          type: "Tag",
        },
        {
          slug: "social-media",
          name: "Social Media",
          postCount: 238752,
          metadata: {
            postCount: 238752,
            coverImage: { id: "1*uOkczl-rzzPqbQTw6PSFYw.jpeg" },
          },
          type: "Tag",
        },
        {
          slug: "music",
          name: "Music",
          postCount: 221400,
          metadata: {
            postCount: 221400,
            coverImage: { id: "1*IiNmmcwgPtVI77r3VcrGjA.jpeg" },
          },
          type: "Tag",
        },
        {
          slug: "programming",
          name: "Programming",
          postCount: 153619,
          metadata: {
            postCount: 153619,
            coverImage: {
              id: "1*uogTxM0a-lVa__VkjFBjew.jpeg",
              originalWidth: 5472,
              originalHeight: 3078,
              isFeatured: true,
            },
          },
          type: "Tag",
        },
        {
          slug: "javascript",
          name: "JavaScript",
          postCount: 137447,
          metadata: {
            postCount: 137447,
            coverImage: {
              id: "0*3yj2i8k764xJZVT7",
              originalWidth: 3000,
              originalHeight: 2000,
              isFeatured: true,
              unsplashPhotoId: "xILBV0M7XDs",
            },
          },
          type: "Tag",
        },
        {
          slug: "web-development",
          name: "Web Development",
          postCount: 130179,
          metadata: {
            postCount: 130179,
            coverImage: {
              id: "0*3yj2i8k764xJZVT7",
              originalWidth: 3000,
              originalHeight: 2000,
              isFeatured: true,
              unsplashPhotoId: "xILBV0M7XDs",
            },
          },
          type: "Tag",
        },
        {
          slug: "artificial-intelligence",
          name: "Artificial Intelligence",
          postCount: 123174,
          metadata: {
            postCount: 123174,
            coverImage: { id: "1*gAn_BSffVBcwCIR6bDgK1g.jpeg" },
          },
          type: "Tag",
        },
        {
          slug: "machine-learning",
          name: "Machine Learning",
          postCount: 121765,
          metadata: {
            postCount: 121765,
            coverImage: {
              id: "1*IswU0VXXt-dzO4eacEE17g.jpeg",
              originalWidth: 4206,
              originalHeight: 2804,
              isFeatured: true,
            },
          },
          type: "Tag",
        },
        {
          slug: "python",
          name: "Python",
          postCount: 57014,
          metadata: {
            postCount: 57014,
            coverImage: {
              id: "1*IyWh6JoVzdhH_NbAL6AqVg.png",
              originalWidth: 1280,
              originalHeight: 1118,
              isFeatured: true,
            },
          },
          type: "Tag",
        },
        {
          slug: "depression",
          name: "Depression",
          postCount: 52511,
          metadata: {
            postCount: 52511,
            coverImage: {
              id: "1*GoxQsGM5JKMyvbwrtVxJZw.jpeg",
              originalWidth: 1920,
              originalHeight: 1080,
              isFeatured: true,
              focusPercentX: 66,
              focusPercentY: 60,
            },
          },
          type: "Tag",
        },
        {
          slug: "ai",
          name: "AI",
          postCount: 52312,
          metadata: {
            postCount: 52312,
            coverImage: {
              id: "1*LYSBdJHi0c1_SV-EhXZFGQ.png",
              originalWidth: 2315,
              originalHeight: 1013,
              isFeatured: true,
            },
          },
          type: "Tag",
        },
        {
          slug: "react",
          name: "React",
          postCount: 41396,
          metadata: {
            postCount: 41396,
            coverImage: {
              id: "0*TlI4gXBrO-_pG2mg",
              originalWidth: 5994,
              originalHeight: 3996,
              isFeatured: true,
              unsplashPhotoId: "JEqAtLhffRY",
            },
          },
          type: "Tag",
        },
        {
          slug: "youtube",
          name: "YouTube",
          postCount: 37310,
          metadata: {
            postCount: 37310,
            coverImage: {
              id: "1*9qS3yM0_HipiqZ4nS0LIaQ.png",
              originalWidth: 2126,
              originalHeight: 1268,
              isFeatured: true,
              focusPercentX: 53,
              focusPercentY: 28,
            },
          },
          type: "Tag",
        },
        {
          slug: "deep-learning",
          name: "Deep Learning",
          postCount: 31118,
          metadata: {
            postCount: 31118,
            coverImage: {
              id: "1*LYSBdJHi0c1_SV-EhXZFGQ.png",
              originalWidth: 2315,
              originalHeight: 1013,
              isFeatured: true,
            },
          },
          type: "Tag",
        },
        {
          slug: "nodejs",
          name: "Nodejs",
          postCount: 27493,
          metadata: {
            postCount: 27493,
            coverImage: {
              id: "0*TlI4gXBrO-_pG2mg",
              originalWidth: 5994,
              originalHeight: 3996,
              isFeatured: true,
              unsplashPhotoId: "JEqAtLhffRY",
            },
          },
          type: "Tag",
        },
        {
          slug: "literature",
          name: "Literature",
          postCount: 22329,
          metadata: {
            postCount: 22329,
            coverImage: {
              id: "1*Pq9Kd1NcoGJKTQtO3h_BjA.jpeg",
              originalWidth: 2000,
              originalHeight: 1200,
              isFeatured: true,
            },
          },
          type: "Tag",
        },
        {
          slug: "api",
          name: "API",
          postCount: 20621,
          metadata: {
            postCount: 20621,
            coverImage: {
              id: "1*rmihLwFBVenF1VLLkVtwQg.jpeg",
              originalWidth: 4000,
              originalHeight: 2250,
              isFeatured: true,
            },
          },
          type: "Tag",
        },
        {
          slug: "english",
          name: "English",
          postCount: 16835,
          metadata: {
            postCount: 16835,
            coverImage: {
              id: "0*C6YBG3G6WCLNBXFD",
              originalWidth: 1000,
              originalHeight: 750,
              isFeatured: true,
            },
          },
          type: "Tag",
        },
        {
          slug: "angular",
          name: "Angular",
          postCount: 13231,
          metadata: {
            postCount: 13231,
            coverImage: {
              id: "1*BnMkmo165Ck2cBqP4bHASg.jpeg",
              originalWidth: 4032,
              originalHeight: 3024,
              isFeatured: true,
            },
          },
          type: "Tag",
        },
        {
          slug: "app-development",
          name: "App Development",
          postCount: 12951,
          metadata: {
            postCount: 12951,
            coverImage: {
              id: "1*JaTYRiQo0wtZ0ya7MObAVA.png",
              originalWidth: 985,
              originalHeight: 532,
              isFeatured: true,
            },
          },
          type: "Tag",
        },
        {
          slug: "database",
          name: "Database",
          postCount: 12264,
          metadata: {
            postCount: 12264,
            coverImage: {
              id: "1*IyWh6JoVzdhH_NbAL6AqVg.png",
              originalWidth: 1280,
              originalHeight: 1118,
              isFeatured: true,
            },
          },
          type: "Tag",
        },
        {
          slug: "web",
          name: "Web",
          postCount: 11347,
          metadata: {
            postCount: 11347,
            coverImage: {
              id: "1*-_KzHG5cPzyJDr7JmfsSkw.png",
              originalWidth: 1072,
              originalHeight: 968,
              isFeatured: true,
            },
          },
          type: "Tag",
        },
        {
          slug: "audio",
          name: "Audio",
          postCount: 10146,
          metadata: {
            postCount: 10146,
            coverImage: {
              id: "1*XNMFcx9c6uzeut1Q3-hoRQ.jpeg",
              originalWidth: 2400,
              originalHeight: 1600,
              isFeatured: true,
              focusPercentX: 47,
              focusPercentY: 26,
            },
          },
          type: "Tag",
        },
        {
          slug: "nlp",
          name: "NLP",
          postCount: 9434,
          metadata: {
            postCount: 9434,
            coverImage: {
              id: "0*s4G3V6_Hf2vdd0zr",
              originalWidth: 2918,
              originalHeight: 3585,
              unsplashPhotoId: "Lg2GnSP4t7k",
            },
          },
          type: "Tag",
        },
        {
          slug: "functional-programming",
          name: "Functional Programming",
          postCount: 8230,
          metadata: {
            postCount: 8230,
            coverImage: {
              id: "1*RPKQq3zYYddwJf9rSeQ4Dg.jpeg",
              originalWidth: 1600,
              originalHeight: 1085,
            },
          },
          type: "Tag",
        },
        {
          slug: "reactjs",
          name: "Reactjs",
          postCount: 7772,
          metadata: {
            postCount: 7772,
            coverImage: {
              id: "1*lhhEj8dRWk2ZpnMufW3nJQ.png",
              originalWidth: 2240,
              originalHeight: 1260,
              isFeatured: true,
            },
          },
          type: "Tag",
        },
        {
          slug: "sql",
          name: "Sql",
          postCount: 7336,
          metadata: {
            postCount: 7336,
            coverImage: {
              id: "1*uogTxM0a-lVa__VkjFBjew.jpeg",
              originalWidth: 5472,
              originalHeight: 3078,
              isFeatured: true,
            },
          },
          type: "Tag",
        },
        {
          slug: "maps",
          name: "Maps",
          postCount: 6663,
          metadata: {
            postCount: 6663,
            coverImage: {
              id: "1*Q4puRN8ssUBicuK0mSUXWQ.jpeg",
              originalWidth: 1800,
              originalHeight: 506,
              isFeatured: true,
            },
          },
          type: "Tag",
        },
        {
          slug: "mongodb",
          name: "Mongodb",
          postCount: 4677,
          metadata: {
            postCount: 4677,
            coverImage: {
              id: "1*rEQl61Dsuub7bLrtOEI74A.png",
              originalWidth: 788,
              originalHeight: 231,
              isFeatured: true,
            },
          },
          type: "Tag",
        },
        {
          slug: "npm",
          name: "NPM",
          postCount: 3400,
          metadata: {
            postCount: 3400,
            coverImage: {
              id: "1*rUS3xDgSlW5Lw-b_ztz7uA.jpeg",
              originalWidth: 1920,
              originalHeight: 1080,
              isFeatured: true,
            },
          },
          type: "Tag",
        },
        {
          slug: "rest",
          name: "Rest",
          postCount: 3360,
          metadata: {
            postCount: 3360,
            coverImage: {
              id: "1*wqrGQ8s6KPQZxjUuBzg8Rg.png",
              originalWidth: 1297,
              originalHeight: 729,
              isFeatured: true,
            },
          },
          type: "Tag",
        },
        {
          slug: "naturallanguageprocessing",
          name: "Naturallanguageprocessing",
          postCount: 3114,
          metadata: {
            postCount: 3114,
            coverImage: {
              id: "1*ArDCmvrJ3pX1liw9eAK1PA.jpeg",
              originalWidth: 1740,
              originalHeight: 1230,
              isFeatured: true,
            },
          },
          type: "Tag",
        },
        {
          slug: "conversational-ui",
          name: "Conversational UI",
          postCount: 2912,
          metadata: {
            postCount: 2912,
            coverImage: {
              id: "1*tbxZ2IEfy_uusLtymFeFhQ.jpeg",
              originalWidth: 4240,
              originalHeight: 3117,
              isFeatured: true,
            },
          },
          type: "Tag",
        },
        {
          slug: "https",
          name: "Https",
          postCount: 2852,
          metadata: {
            postCount: 2852,
            coverImage: {
              id: "1*Sd50m5VidzQA8wzhJiFHhQ.jpeg",
              originalWidth: 6016,
              originalHeight: 4016,
              isFeatured: true,
            },
          },
          type: "Tag",
        },
        {
          slug: "bootstrap",
          name: "Bootstrap",
          postCount: 2740,
          metadata: {
            postCount: 2740,
            coverImage: {
              id: "1*kUQ0Nvp_Lflm6joTrAIqjg.png",
              originalWidth: 1280,
              originalHeight: 718,
              isFeatured: true,
              alt: "Material Design for Bootstrap 5",
            },
          },
          type: "Tag",
        },
        {
          slug: "js",
          name: "Js",
          postCount: 2409,
          metadata: {
            postCount: 2409,
            coverImage: {
              id: "1*ofDaO2cdRLIJtJJBxjRC0A.png",
              originalWidth: 800,
              originalHeight: 707,
              isFeatured: true,
            },
          },
          type: "Tag",
        },
        {
          slug: "lambda",
          name: "Lambda",
          postCount: 2369,
          metadata: {
            postCount: 2369,
            coverImage: {
              id: "1*xWMWzHqY4MBdxIzUcAYzqg.jpeg",
              originalWidth: 600,
              originalHeight: 338,
              isFeatured: true,
            },
          },
          type: "Tag",
        },
        {
          slug: "nosql",
          name: "NoSQL",
          postCount: 2092,
          metadata: {
            postCount: 2092,
            coverImage: {
              id: "0*2tu2OVfNswUAH-oz",
              originalWidth: 2877,
              originalHeight: 1918,
              isFeatured: true,
              unsplashPhotoId: "gpjvRZyavZc",
            },
          },
          type: "Tag",
        },
        {
          slug: "ielts",
          name: "Ielts",
          postCount: 2047,
          metadata: {
            postCount: 2047,
            coverImage: {
              id: "1*8Po_DyDtQMqk6hebrTnksw.png",
              originalWidth: 1500,
              originalHeight: 750,
              isFeatured: true,
            },
          },
          type: "Tag",
        },
        {
          slug: "react-hook",
          name: "React Hook",
          postCount: 1426,
          metadata: {
            postCount: 1426,
            coverImage: {
              id: "1*FZG3T1_HeYFsPR395xppHQ.jpeg",
              originalWidth: 2683,
              originalHeight: 1102,
              isFeatured: true,
            },
          },
          type: "Tag",
        },
        {
          slug: "discord",
          name: "Discord",
          postCount: 1240,
          metadata: {
            postCount: 1240,
            coverImage: {
              id: "1*maPImYoUp2vfPm8DuSCjFQ.png",
              originalWidth: 2000,
              originalHeight: 800,
            },
          },
          type: "Tag",
        },
        {
          slug: "speech-recognition",
          name: "Speech Recognition",
          postCount: 1140,
          metadata: {
            postCount: 1140,
            coverImage: {
              id: "1*V2mgZ7y0ngd3q4DZ01xkEQ.png",
              originalWidth: 1652,
              originalHeight: 946,
              isFeatured: true,
            },
          },
          type: "Tag",
        },
        {
          slug: "filters",
          name: "Filters",
          postCount: 1099,
          metadata: {
            postCount: 1099,
            coverImage: {
              id: "1*t46JciFvCtJWS6f4_NfuSg.png",
              originalWidth: 1050,
              originalHeight: 327,
              isFeatured: true,
            },
          },
          type: "Tag",
        },
        {
          slug: "pomodoro-technique",
          name: "Pomodoro Technique",
          postCount: 1091,
          metadata: {
            postCount: 1091,
            coverImage: {
              id: "1*-gZawMvSWjeIF21YIGq-mg.jpeg",
              originalWidth: 6000,
              originalHeight: 4000,
              isFeatured: true,
            },
          },
          type: "Tag",
        },
        {
          slug: "lstm",
          name: "Lstm",
          postCount: 679,
          metadata: {
            postCount: 679,
            coverImage: {
              id: "1*n-IgHZM5baBUjq0T7RYDBw.gif",
              originalWidth: 960,
              originalHeight: 540,
              isFeatured: true,
            },
          },
          type: "Tag",
        },
        {
          slug: "signal-processing",
          name: "Signal Processing",
          postCount: 350,
          metadata: {
            postCount: 350,
            coverImage: {
              id: "1*5PvyfQe5lnsz8D6Kz98I3g.png",
              originalWidth: 1321,
              originalHeight: 395,
              isFeatured: true,
            },
          },
          type: "Tag",
        },
        {
          slug: "effect",
          name: "Effect",
          postCount: 323,
          metadata: {
            postCount: 323,
            coverImage: {
              id: "1*0FeHl5txunXAsspjlkLPkA.jpeg",
              originalWidth: 878,
              originalHeight: 527,
              isFeatured: true,
            },
          },
          type: "Tag",
        },
        {
          slug: "reduce",
          name: "Reduce",
          postCount: 279,
          metadata: {
            postCount: 279,
            coverImage: {
              id: "1*-Q4Vl5RxPsagdToQZphmiQ.jpeg",
              originalWidth: 1900,
              originalHeight: 1267,
              isFeatured: true,
            },
          },
          type: "Tag",
        },
        {
          slug: "semantic-versioning",
          name: "Semantic Versioning",
          postCount: 102,
          metadata: {
            postCount: 102,
            coverImage: {
              id: "1*64X80QhKOUWDCpm-lRVaqg.png",
              originalWidth: 1172,
              originalHeight: 568,
              isFeatured: true,
            },
          },
          type: "Tag",
        },
        {
          slug: "ascii",
          name: "Ascii",
          postCount: 101,
          metadata: {
            postCount: 101,
            coverImage: {
              id: "1*YZ5Cw2NIXcobBi1ayeP3rw.jpeg",
              originalWidth: 1280,
              originalHeight: 720,
              isFeatured: true,
            },
          },
          type: "Tag",
        },
        {
          slug: "ielts-preparation",
          name: "Ielts Preparation",
          postCount: 51,
          metadata: {
            postCount: 51,
            coverImage: {
              id: "1*v72sOAqwIlM81YD3ydkQ5g.png",
              originalWidth: 675,
              originalHeight: 319,
              isFeatured: true,
            },
          },
          type: "Tag",
        },
        {
          slug: "packagejson",
          name: "Packagejson",
          postCount: 37,
          metadata: {
            postCount: 37,
            coverImage: {
              id: "1*AgzA7GaMKIwKIg9iYEB06w.png",
              originalWidth: 1323,
              originalHeight: 694,
              isFeatured: true,
            },
          },
          type: "Tag",
        },
        {
          slug: "social-media-effects",
          name: "Social Media Effects",
          postCount: 14,
          metadata: {
            postCount: 14,
            coverImage: {
              id: "1*nXvbtOY9TCGcNOxqPLgR1Q.jpeg",
              originalWidth: 626,
              originalHeight: 626,
              isFeatured: true,
            },
          },
          type: "Tag",
        },
        {
          slug: "angular-4",
          name: "Angular 4",
          postCount: 13,
          metadata: {
            postCount: 13,
            coverImage: {
              id: "1*7PhBQusPtrHbEQzmDq3qGg.png",
              originalWidth: 1684,
              originalHeight: 1232,
              isFeatured: true,
            },
          },
          type: "Tag",
        },
        {
          slug: "csv-conf",
          name: "Csv Conf",
          postCount: 9,
          metadata: {
            postCount: 9,
            coverImage: {
              id: "1*rR0LcGP-rGEULvZRZTvBDQ.png",
              originalWidth: 564,
              originalHeight: 240,
              isFeatured: true,
            },
          },
          type: "Tag",
        },
      ],
      featuredPostId: "555b765f2645",
      topWriterInTags: [],
      type: "UserMeta",
    },
    userNavItemList: {
      userNavItems: [
        {
          title: "Profile",
          url: "https://medium.com/@anuragbhattacharjee",
          systemItem: { systemType: 1 },
          navType: "systemItem",
        },
        {
          title: "Claps",
          url: "https://medium.com/@anuragbhattacharjee/has-recommended",
          systemItem: { systemType: 4 },
          navType: "systemItem",
        },
        {
          title: "Highlights",
          url: "https://medium.com/@anuragbhattacharjee/highlights",
          systemItem: { systemType: 5 },
          navType: "systemItem",
        },
        {
          title: "Responses",
          url: "https://medium.com/@anuragbhattacharjee/responses",
          systemItem: { systemType: 3 },
          navType: "systemItem",
        },
      ],
    },
    userNavActiveIndex: -1,
    profileTypeName: "latest",
    isStandaloneEditPage: false,
    references: {
      User: {
        "9a619946f362": {
          userId: "9a619946f362",
          name: "Anurag Bhattacharjee",
          username: "anuragbhattacharjee",
          createdAt: 1455536048517,
          imageId: "0*2FAy25grUtBNGwtK.jpg",
          backgroundImageId: "",
          bio: "",
          twitterScreenName: "AnuragOn2",
          allowNotes: 1,
          mediumMemberAt: 0,
          isWriterProgramEnrolled: true,
          isSuspended: false,
          isMembershipTrialEligible: true,
          optInToIceland: false,
          type: "User",
        },
      },
      Post: {
        fec831d30d1b: {
          id: "fec831d30d1b",
          versionId: "f14cdc94e5c",
          creatorId: "9a619946f362",
          homeCollectionId: "",
          title:
            "I created a Deep Learning powered Discord Bot to react with smily 😎",
          detectedLanguage: "en",
          latestVersion: "f14cdc94e5c",
          latestPublishedVersion: "f14cdc94e5c",
          hasUnpublishedEdits: false,
          latestRev: 835,
          createdAt: 1595498856300,
          updatedAt: 1595936189108,
          acceptedAt: 0,
          firstPublishedAt: 1595936188850,
          latestPublishedAt: 1595936188850,
          vote: false,
          experimentalCss: "",
          displayAuthor: "",
          content: {
            subtitle:
              "I have created a discord bot to read messages and detect the sentiment, emotion and sarcasm of the message according to it’s 1.2B twitter…",
            postDisplay: { coverless: true },
          },
          virtuals: {
            allowNotes: true,
            previewImage: {
              imageId: "1*w02fkd_obHhnGwPRaCgm1w.png",
              filter: "",
              backgroundSize: "",
              originalWidth: 2098,
              originalHeight: 930,
              strategy: "resample",
              height: 0,
              width: 0,
            },
            wordCount: 1348,
            imageCount: 2,
            readingTime: 5.470125786163522,
            subtitle:
              "I have created a discord bot to read messages and detect the sentiment, emotion and sarcasm of the message according to it’s 1.2B twitter…",
            userPostRelation: {
              userId: "9a619946f362",
              postId: "fec831d30d1b",
              readAt: 1595936451973,
              readLaterAddedAt: 0,
              votedAt: 0,
              collaboratorAddedAt: 0,
              notesAddedAt: 0,
              subscribedAt: 0,
              lastReadSectionName: "",
              lastReadVersionId: "",
              lastReadAt: 0,
              lastReadParagraphName: "",
              lastReadPercentage: 0,
              viewedAt: 1595942178804,
              presentedCountInResponseManagement: 0,
              clapCount: 0,
              seriesUpdateNotifsOptedInAt: 0,
              queuedAt: 0,
              seriesFirstViewedAt: 0,
              presentedCountInStream: 1,
              seriesLastViewedAt: 0,
              audioProgressSec: 0,
            },
            usersBySocialRecommends: [],
            noIndex: false,
            recommends: 0,
            isBookmarked: false,
            tags: [
              {
                slug: "nlp",
                name: "NLP",
                postCount: 9434,
                metadata: {
                  postCount: 9434,
                  coverImage: {
                    id: "0*s4G3V6_Hf2vdd0zr",
                    originalWidth: 2918,
                    originalHeight: 3585,
                    unsplashPhotoId: "Lg2GnSP4t7k",
                  },
                },
                type: "Tag",
              },
              {
                slug: "lstm",
                name: "Lstm",
                postCount: 679,
                metadata: {
                  postCount: 679,
                  coverImage: {
                    id: "1*n-IgHZM5baBUjq0T7RYDBw.gif",
                    originalWidth: 960,
                    originalHeight: 540,
                    isFeatured: true,
                  },
                },
                type: "Tag",
              },
              {
                slug: "naturallanguageprocessing",
                name: "Naturallanguageprocessing",
                postCount: 3114,
                metadata: {
                  postCount: 3114,
                  coverImage: {
                    id: "1*ArDCmvrJ3pX1liw9eAK1PA.jpeg",
                    originalWidth: 1740,
                    originalHeight: 1230,
                    isFeatured: true,
                  },
                },
                type: "Tag",
              },
              {
                slug: "discord",
                name: "Discord",
                postCount: 1240,
                metadata: {
                  postCount: 1240,
                  coverImage: {
                    id: "1*maPImYoUp2vfPm8DuSCjFQ.png",
                    originalWidth: 2000,
                    originalHeight: 800,
                  },
                },
                type: "Tag",
              },
              {
                slug: "deep-learning",
                name: "Deep Learning",
                postCount: 31118,
                metadata: {
                  postCount: 31118,
                  coverImage: {
                    id: "1*LYSBdJHi0c1_SV-EhXZFGQ.png",
                    originalWidth: 2315,
                    originalHeight: 1013,
                    isFeatured: true,
                  },
                },
                type: "Tag",
              },
            ],
            socialRecommendsCount: 0,
            responsesCreatedCount: 0,
            links: {
              entries: [
                { url: "/@anuragbhattacharjee", alts: [], httpStatus: 0 },
                {
                  url: "https://www.linkedin.com/in/anuragbhattacharjee/",
                  alts: [],
                  httpStatus: 999,
                },
                {
                  url:
                    "https://discordpy.readthedocs.io/en/latest/discord.html",
                  alts: [],
                  httpStatus: 200,
                },
                {
                  url: "https://discord.com/developers/applications",
                  alts: [],
                  httpStatus: 200,
                },
                { url: "http://jupyter.org", alts: [], httpStatus: 200 },
                {
                  url:
                    "https://discordpy.readthedocs.io/en/latest/api.html#event-reference",
                  alts: [],
                  httpStatus: 200,
                },
                {
                  url: "https://arxiv.org/abs/1706.03762",
                  alts: [],
                  httpStatus: 200,
                },
                {
                  url: "https://arxiv.org/abs/1708.00524",
                  alts: [],
                  httpStatus: 200,
                },
                { url: "http://huggingface.co", alts: [], httpStatus: 200 },
                {
                  url:
                    "https://github.com/anuragbhattacharjee/discord-nlp-smily-bot/tree/master",
                  alts: [],
                  httpStatus: 200,
                },
                {
                  url:
                    "https://github.com/anuragbhattacharjee/discord-nlp-smily-bot/",
                  alts: [],
                  httpStatus: 200,
                },
                {
                  url: "https://github.com/bfelbo/DeepMoji",
                  alts: [],
                  httpStatus: 200,
                },
                {
                  url: "https://github.com/huggingface/torchMoji",
                  alts: [],
                  httpStatus: 200,
                },
                {
                  url: "http://anuragbhattacharjee.com",
                  alts: [],
                  httpStatus: 200,
                },
                { url: "https://conda.io", alts: [], httpStatus: 200 },
                {
                  url: "https://www.bioinf.jku.at/publications/older/2604.pdf",
                  alts: [],
                  httpStatus: 200,
                },
              ],
              version: "0.3",
              generatedAt: 1595936190468,
            },
            isLockedPreviewOnly: false,
            metaDescription: "",
            totalClapCount: 0,
            sectionCount: 3,
            readingList: 0,
            topics: [
              {
                topicId: "1eca0103fff3",
                slug: "machine-learning",
                createdAt: 1534449726145,
                deletedAt: 0,
                image: {
                  id: "1*gFJS3amhZEg_z39D5EErVg@2x.png",
                  originalWidth: 2800,
                  originalHeight: 1750,
                },
                name: "Machine Learning",
                description: "Teaching the learners.",
                relatedTopics: [],
                visibility: 1,
                relatedTags: [],
                relatedTopicIds: [],
                seoTitle: "Machine Learning News and Articles — Medium",
                type: "Topic",
              },
            ],
          },
          coverless: true,
          slug:
            "i-created-a-deep-learning-powered-discord-bot-to-react-with-smily",
          translationSourcePostId: "",
          translationSourceCreatorId: "",
          isApprovedTranslation: false,
          inResponseToPostId: "",
          inResponseToRemovedAt: 0,
          isTitleSynthesized: false,
          allowResponses: true,
          importedUrl: "",
          importedPublishedAt: 0,
          visibility: 0,
          uniqueSlug:
            "i-created-a-deep-learning-powered-discord-bot-to-react-with-smily-fec831d30d1b",
          previewContent: {
            bodyModel: {
              paragraphs: [
                {
                  name: "previewImage",
                  type: 4,
                  text: "",
                  layout: 10,
                  metadata: {
                    id: "1*w02fkd_obHhnGwPRaCgm1w.png",
                    originalWidth: 2098,
                    originalHeight: 930,
                    isFeatured: true,
                  },
                },
                {
                  name: "432b",
                  type: 3,
                  text:
                    "I created a Deep Learning powered Discord Bot to react with smily 😎",
                  markups: [],
                  alignment: 1,
                },
                {
                  name: "f6ea",
                  type: 13,
                  text: "I have created a discord bot to…",
                  markups: [],
                  alignment: 1,
                },
              ],
              sections: [{ startIndex: 0 }],
            },
            isFullContent: false,
            subtitle:
              "I have created a discord bot to read messages and detect the sentiment, emotion and sarcasm of the message according to it’s 1.2B twitter…",
          },
          license: 0,
          inResponseToMediaResourceId: "",
          canonicalUrl: "",
          approvedHomeCollectionId: "",
          newsletterId: "",
          webCanonicalUrl: "",
          mediumUrl: "",
          migrationId: "",
          notifyFollowers: true,
          notifyTwitter: false,
          notifyFacebook: false,
          responseHiddenOnParentPostAt: 0,
          isSeries: false,
          isSubscriptionLocked: false,
          seriesLastAppendedAt: 0,
          audioVersionDurationSec: 0,
          sequenceId: "",
          isEligibleForRevenue: false,
          isBlockedFromHightower: false,
          deletedAt: 0,
          lockedPostSource: 0,
          hightowerMinimumGuaranteeStartsAt: 0,
          hightowerMinimumGuaranteeEndsAt: 0,
          featureLockRequestAcceptedAt: 0,
          mongerRequestType: 1,
          layerCake: 0,
          socialTitle: "",
          socialDek: "",
          editorialPreviewTitle: "",
          editorialPreviewDek: "",
          curationEligibleAt: 1595936187754,
          isProxyPost: false,
          proxyPostFaviconUrl: "",
          proxyPostProviderName: "",
          proxyPostType: 0,
          isSuspended: false,
          isLimitedState: false,
          seoTitle: "",
          previewContent2: {
            bodyModel: {
              paragraphs: [
                {
                  name: "432b",
                  type: 3,
                  text:
                    "I created a Deep Learning powered Discord Bot to react with smily 😎",
                  markups: [],
                },
                {
                  name: "f6ea",
                  type: 13,
                  text:
                    "I have created a discord bot to read messages and detect the sentiment, emotion and sarcasm of the message according to it’s 1.2B twitter trained data and reply with a suitable smily. I have deployed it in my office server to have some fun with it.",
                  markups: [],
                },
                {
                  name: "9d99",
                  type: 4,
                  text: "smily-bot with my colleagues",
                  markups: [],
                  layout: 5,
                  metadata: {
                    id: "1*w02fkd_obHhnGwPRaCgm1w.png",
                    originalWidth: 2098,
                    originalHeight: 930,
                    isFeatured: true,
                  },
                },
                { name: "6909", type: 3, text: "Motivation", markups: [] },
                {
                  name: "aeff",
                  type: 1,
                  text:
                    "I have been reading a lot of paper articles lately, mostly on Deep Learning, RNN, CNN, LSTM[1], Transformer(Attention is all you need)[3], BERT etc. The list is only growing. But the best way to learn anything is to experience it. And also if I deploy a bot with my face…",
                  markups: [],
                },
              ],
              sections: [{ startIndex: 0 }],
            },
            isFullContent: false,
            subtitle:
              "I have created a discord bot to read messages and detect the sentiment, emotion and sarcasm of the message according to it’s 1.2B twitter…",
          },
          cardType: 0,
          isDistributionAlertDismissed: false,
          isShortform: false,
          shortformType: 0,
          responsesLocked: false,
          isLockedResponse: false,
          type: "Post",
        },
        ef66e9c942b7: {
          id: "ef66e9c942b7",
          versionId: "17d5aff05227",
          creatorId: "9a619946f362",
          homeCollectionId: "cf8fcf569422",
          title: "Natural Language Processing will change the world.",
          detectedLanguage: "en",
          latestVersion: "17d5aff05227",
          latestPublishedVersion: "17d5aff05227",
          hasUnpublishedEdits: false,
          latestRev: 1009,
          createdAt: 1595322602535,
          updatedAt: 1595937836218,
          acceptedAt: 0,
          firstPublishedAt: 1595346617550,
          latestPublishedAt: 1595937836077,
          vote: false,
          experimentalCss: "",
          displayAuthor: "",
          content: {
            subtitle:
              "Welcome to 2050!! where Natural language processing has changed your daily experience in life.",
            postDisplay: { coverless: true },
          },
          virtuals: {
            statusForCollection: "APPROVED",
            allowNotes: true,
            previewImage: {
              imageId: "1*97kDxD2F4pSgexEBl1EWXQ.gif",
              filter: "",
              backgroundSize: "",
              originalWidth: 480,
              originalHeight: 270,
              strategy: "resample",
              height: 0,
              width: 0,
            },
            wordCount: 1339,
            imageCount: 2,
            readingTime: 5.4361635220125795,
            subtitle:
              "Welcome to 2050!! where Natural language processing has changed your daily experience in life.",
            userPostRelation: {
              userId: "9a619946f362",
              postId: "ef66e9c942b7",
              readAt: 1595941717751,
              readLaterAddedAt: 0,
              votedAt: 0,
              collaboratorAddedAt: 0,
              notesAddedAt: 0,
              subscribedAt: 0,
              lastReadSectionName: "6c38",
              lastReadVersionId: "267c3a433568",
              lastReadAt: 1595581525623,
              lastReadParagraphName: "6e75",
              lastReadPercentage: 0,
              viewedAt: 1595941670404,
              presentedCountInResponseManagement: 0,
              clapCount: 0,
              seriesUpdateNotifsOptedInAt: 0,
              queuedAt: 0,
              seriesFirstViewedAt: 0,
              presentedCountInStream: 4,
              seriesLastViewedAt: 0,
              audioProgressSec: 0,
            },
            publishedInCount: 1,
            usersBySocialRecommends: [],
            noIndex: false,
            recommends: 0,
            isBookmarked: false,
            tags: [
              {
                slug: "naturallanguageprocessing",
                name: "Naturallanguageprocessing",
                postCount: 3114,
                metadata: {
                  postCount: 3114,
                  coverImage: {
                    id: "1*ArDCmvrJ3pX1liw9eAK1PA.jpeg",
                    originalWidth: 1740,
                    originalHeight: 1230,
                    isFeatured: true,
                  },
                },
                type: "Tag",
              },
              {
                slug: "speech-recognition",
                name: "Speech Recognition",
                postCount: 1140,
                metadata: {
                  postCount: 1140,
                  coverImage: {
                    id: "1*V2mgZ7y0ngd3q4DZ01xkEQ.png",
                    originalWidth: 1652,
                    originalHeight: 946,
                    isFeatured: true,
                  },
                },
                type: "Tag",
              },
              {
                slug: "signal-processing",
                name: "Signal Processing",
                postCount: 350,
                metadata: {
                  postCount: 350,
                  coverImage: {
                    id: "1*5PvyfQe5lnsz8D6Kz98I3g.png",
                    originalWidth: 1321,
                    originalHeight: 395,
                    isFeatured: true,
                  },
                },
                type: "Tag",
              },
              {
                slug: "audio",
                name: "Audio",
                postCount: 10146,
                metadata: {
                  postCount: 10146,
                  coverImage: {
                    id: "1*XNMFcx9c6uzeut1Q3-hoRQ.jpeg",
                    originalWidth: 2400,
                    originalHeight: 1600,
                    isFeatured: true,
                    focusPercentX: 47,
                    focusPercentY: 26,
                  },
                },
                type: "Tag",
              },
              {
                slug: "conversational-ui",
                name: "Conversational UI",
                postCount: 2912,
                metadata: {
                  postCount: 2912,
                  coverImage: {
                    id: "1*tbxZ2IEfy_uusLtymFeFhQ.jpeg",
                    originalWidth: 4240,
                    originalHeight: 3117,
                    isFeatured: true,
                  },
                },
                type: "Tag",
              },
            ],
            socialRecommendsCount: 0,
            responsesCreatedCount: 0,
            links: {
              entries: [
                { url: "/@anuragbhattacharjee", alts: [], httpStatus: 0 },
                {
                  url: "https://www.linkedin.com/in/anuragbhattacharjee/",
                  alts: [],
                  httpStatus: 999,
                },
                {
                  url: "https://thegoodplace.fandom.com/wiki/Janet",
                  alts: [],
                  httpStatus: 200,
                },
                {
                  url:
                    "https://www.google.com/search?client=safari&rls=en&q=dope+meaning&ie=UTF-8&oe=UTF-8",
                  alts: [],
                  httpStatus: 200,
                },
                {
                  url: "http://anuragbhattacharjee.com",
                  alts: [],
                  httpStatus: 200,
                },
              ],
              version: "0.3",
              generatedAt: 1595937836934,
            },
            isLockedPreviewOnly: false,
            metaDescription: "",
            totalClapCount: 0,
            sectionCount: 5,
            readingList: 0,
            topics: [
              {
                topicId: "1af65db9c2f8",
                slug: "artificial-intelligence",
                createdAt: 1487916832419,
                deletedAt: 0,
                image: {
                  id: "1*A28aHchbaA8zNVXraBq0Ug@2x.jpeg",
                  originalWidth: 4866,
                  originalHeight: 3244,
                },
                name: "Artificial Intelligence",
                description: "Born to be bot.",
                relatedTopics: [],
                visibility: 1,
                relatedTags: [],
                relatedTopicIds: [],
                seoTitle:
                  "AI News and Artificial Intelligence Articles — Medium",
                type: "Topic",
              },
            ],
          },
          coverless: true,
          slug: "natural-language-processing-will-change-the-world",
          translationSourcePostId: "",
          translationSourceCreatorId: "",
          isApprovedTranslation: false,
          inResponseToPostId: "",
          inResponseToRemovedAt: 0,
          isTitleSynthesized: false,
          allowResponses: true,
          importedUrl: "",
          importedPublishedAt: 0,
          visibility: 0,
          uniqueSlug:
            "natural-language-processing-will-change-the-world-ef66e9c942b7",
          previewContent: {
            bodyModel: {
              paragraphs: [
                {
                  name: "previewImage",
                  type: 4,
                  text: "",
                  layout: 10,
                  metadata: {
                    id: "1*97kDxD2F4pSgexEBl1EWXQ.gif",
                    originalWidth: 480,
                    originalHeight: 270,
                    isFeatured: true,
                  },
                },
                {
                  name: "6e75",
                  type: 3,
                  text: "Natural Language Processing will change the world.",
                  markups: [],
                  alignment: 1,
                },
              ],
              sections: [{ startIndex: 0 }],
            },
            isFullContent: false,
            subtitle:
              "Welcome to 2050!! where Natural language processing has changed your daily experience in life.",
          },
          license: 0,
          inResponseToMediaResourceId: "",
          canonicalUrl: "",
          approvedHomeCollectionId: "cf8fcf569422",
          newsletterId: "",
          webCanonicalUrl: "",
          mediumUrl: "",
          migrationId: "",
          notifyFollowers: true,
          notifyTwitter: false,
          notifyFacebook: false,
          responseHiddenOnParentPostAt: 0,
          isSeries: false,
          isSubscriptionLocked: false,
          seriesLastAppendedAt: 0,
          audioVersionDurationSec: 0,
          sequenceId: "",
          isEligibleForRevenue: false,
          isBlockedFromHightower: false,
          deletedAt: 0,
          lockedPostSource: 0,
          hightowerMinimumGuaranteeStartsAt: 0,
          hightowerMinimumGuaranteeEndsAt: 0,
          featureLockRequestAcceptedAt: 0,
          mongerRequestType: 1,
          layerCake: 0,
          socialTitle: "",
          socialDek: "",
          editorialPreviewTitle: "",
          editorialPreviewDek: "",
          curationEligibleAt: 1595346616210,
          isProxyPost: false,
          proxyPostFaviconUrl: "",
          proxyPostProviderName: "",
          proxyPostType: 0,
          isSuspended: false,
          isLimitedState: false,
          seoTitle: "",
          previewContent2: {
            bodyModel: {
              paragraphs: [
                {
                  name: "6e75",
                  type: 3,
                  text: "Natural Language Processing will change the world.",
                  markups: [],
                },
                {
                  name: "3218",
                  type: 1,
                  text:
                    "Welcome to 2050!! where Natural language processing has changed your daily experience in life.",
                  markups: [{ type: 2, start: 0, end: 94 }],
                },
                {
                  name: "df90",
                  type: 4,
                  text: "via Neflix/Dark",
                  markups: [],
                  layout: 1,
                  metadata: {
                    id: "1*97kDxD2F4pSgexEBl1EWXQ.gif",
                    originalWidth: 480,
                    originalHeight: 270,
                    isFeatured: true,
                  },
                },
                {
                  name: "3cf5",
                  type: 11,
                  text: "",
                  markups: [],
                  layout: 1,
                  iframe: {
                    mediaResourceId: "d6a9e202d4ee773dbc23199fcf6d186c",
                    iframeWidth: 480,
                    iframeHeight: 110,
                  },
                },
                { name: "3bab", type: 3, text: "2050 !!", markups: [] },
                {
                  name: "890b",
                  type: 1,
                  text:
                    "Welcome to 2050. You have a personal assistant who is not a human, but an app, a simple mobile app. S/he is your best friend. From sharing secrets of your life, to gossip just to…",
                  markups: [],
                  hasDropCap: true,
                },
              ],
              sections: [{ startIndex: 0 }],
            },
            isFullContent: false,
            subtitle:
              "Welcome to 2050!! where Natural language processing has changed your daily experience in life.",
          },
          cardType: 0,
          isDistributionAlertDismissed: false,
          isShortform: false,
          shortformType: 0,
          responsesLocked: false,
          isLockedResponse: false,
          type: "Post",
        },
        "309d63cfbcba": {
          id: "309d63cfbcba",
          versionId: "a57600a22ede",
          creatorId: "9a619946f362",
          homeCollectionId: "",
          title: "DIY Music Player with React js 📻",
          detectedLanguage: "en",
          latestVersion: "a57600a22ede",
          latestPublishedVersion: "a57600a22ede",
          hasUnpublishedEdits: false,
          latestRev: 1207,
          createdAt: 1591282660386,
          updatedAt: 1593520897305,
          acceptedAt: 0,
          firstPublishedAt: 1593520897039,
          latestPublishedAt: 1593520897039,
          vote: false,
          experimentalCss: "",
          displayAuthor: "",
          content: {
            subtitle:
              "Distraction-free music player to search for music from youtube and play the audio.",
            postDisplay: { coverless: true },
          },
          virtuals: {
            allowNotes: true,
            previewImage: {
              imageId: "1*p3aXdoG0I41Dvx0bPco9MQ.png",
              filter: "",
              backgroundSize: "",
              originalWidth: 1917,
              originalHeight: 943,
              strategy: "resample",
              height: 0,
              width: 0,
            },
            wordCount: 1616,
            imageCount: 8,
            readingTime: 7.231446540880503,
            subtitle:
              "Distraction-free music player to search for music from youtube and play the audio.",
            userPostRelation: {
              userId: "9a619946f362",
              postId: "309d63cfbcba",
              readAt: 1595935523296,
              readLaterAddedAt: 0,
              votedAt: 0,
              collaboratorAddedAt: 0,
              notesAddedAt: 0,
              subscribedAt: 0,
              lastReadSectionName: "07a4",
              lastReadVersionId: "a57600a22ede",
              lastReadAt: 1594970661255,
              lastReadParagraphName: "1b79",
              lastReadPercentage: 0,
              viewedAt: 1595932934510,
              presentedCountInResponseManagement: 0,
              clapCount: 0,
              seriesUpdateNotifsOptedInAt: 0,
              queuedAt: 0,
              seriesFirstViewedAt: 0,
              presentedCountInStream: 4,
              seriesLastViewedAt: 0,
              audioProgressSec: 0,
            },
            usersBySocialRecommends: [],
            noIndex: false,
            recommends: 7,
            isBookmarked: false,
            tags: [
              {
                slug: "react",
                name: "React",
                postCount: 41396,
                metadata: {
                  postCount: 41396,
                  coverImage: {
                    id: "0*TlI4gXBrO-_pG2mg",
                    originalWidth: 5994,
                    originalHeight: 3996,
                    isFeatured: true,
                    unsplashPhotoId: "JEqAtLhffRY",
                  },
                },
                type: "Tag",
              },
              {
                slug: "youtube",
                name: "YouTube",
                postCount: 37310,
                metadata: {
                  postCount: 37310,
                  coverImage: {
                    id: "1*9qS3yM0_HipiqZ4nS0LIaQ.png",
                    originalWidth: 2126,
                    originalHeight: 1268,
                    isFeatured: true,
                    focusPercentX: 53,
                    focusPercentY: 28,
                  },
                },
                type: "Tag",
              },
              {
                slug: "app-development",
                name: "App Development",
                postCount: 12951,
                metadata: {
                  postCount: 12951,
                  coverImage: {
                    id: "1*JaTYRiQo0wtZ0ya7MObAVA.png",
                    originalWidth: 985,
                    originalHeight: 532,
                    isFeatured: true,
                  },
                },
                type: "Tag",
              },
              {
                slug: "music",
                name: "Music",
                postCount: 221400,
                metadata: {
                  postCount: 221400,
                  coverImage: { id: "1*IiNmmcwgPtVI77r3VcrGjA.jpeg" },
                },
                type: "Tag",
              },
              {
                slug: "web-development",
                name: "Web Development",
                postCount: 130179,
                metadata: {
                  postCount: 130179,
                  coverImage: {
                    id: "0*3yj2i8k764xJZVT7",
                    originalWidth: 3000,
                    originalHeight: 2000,
                    isFeatured: true,
                    unsplashPhotoId: "xILBV0M7XDs",
                  },
                },
                type: "Tag",
              },
            ],
            socialRecommendsCount: 0,
            responsesCreatedCount: 2,
            links: {
              entries: [
                { url: "https://reactjs.org/", alts: [], httpStatus: 200 },
                {
                  url: "http://webpod.anuragbhattache.com",
                  alts: [],
                  httpStatus: 0,
                },
                {
                  url: "https://www.googleapis.com/youtube/v3/",
                  alts: [],
                  httpStatus: 404,
                },
                {
                  url: "https://www.linkedin.com/in/anuragbhattacharjee/",
                  alts: [],
                  httpStatus: 999,
                },
                {
                  url: "https://www.youtube.com/iframe_api",
                  alts: [],
                  httpStatus: 200,
                },
                {
                  url: "https://reactjs.org/docs/hello-world.html",
                  alts: [],
                  httpStatus: 200,
                },
                {
                  url: "https://facebook.github.io/react/",
                  alts: [],
                  httpStatus: 200,
                },
                {
                  url: "https://www.npmjs.com/package/dotenv",
                  alts: [],
                  httpStatus: 200,
                },
                {
                  url: "https://styled-components.com/",
                  alts: [],
                  httpStatus: 200,
                },
                {
                  url: "https://console.developers.google.com/",
                  alts: [],
                  httpStatus: 200,
                },
                {
                  url:
                    "https://developers.google.com/youtube/iframe_api_reference#loadVideoById",
                  alts: [],
                  httpStatus: 200,
                },
                {
                  url:
                    "https://github.com/anuragbhattacharjee/webpod/tree/master/src/components/toggle",
                  alts: [],
                  httpStatus: 200,
                },
                {
                  url:
                    "https://github.com/anuragbhattacharjee/webpod/blob/master/src/components/player.jsx",
                  alts: [],
                  httpStatus: 200,
                },
                {
                  url:
                    "https://github.com/anuragbhattacharjee/webpod/blob/master/src/components/command.jsx",
                  alts: [],
                  httpStatus: 200,
                },
                {
                  url:
                    "https://github.com/anuragbhattacharjee/webpod/blob/master/src/components/navbar.jsx",
                  alts: [],
                  httpStatus: 200,
                },
                {
                  url:
                    "https://github.com/anuragbhattacharjee/webpod/blob/master/src/components/VideoDetail.jsx",
                  alts: [],
                  httpStatus: 200,
                },
                {
                  url: "https://github.com/anuragbhattacharjee/webpod",
                  alts: [],
                  httpStatus: 200,
                },
                {
                  url:
                    "https://github.com/anuragbhattacharjee/webpod/blob/master/src/services/youtube.js",
                  alts: [],
                  httpStatus: 200,
                },
                {
                  url:
                    "https://github.com/anuragbhattacharjee/webpod/blob/master/src/components/instructions/index.jsx",
                  alts: [],
                  httpStatus: 200,
                },
                {
                  url: "http://anuragbhattacharjee.com",
                  alts: [],
                  httpStatus: 200,
                },
                {
                  url: "http://anuragbhattacharjee.com/",
                  alts: [],
                  httpStatus: 200,
                },
                {
                  url: "http://webpod.anuragbhattacharjee.com",
                  alts: [],
                  httpStatus: 200,
                },
                {
                  url: "http://webpod.anuragbhattacharjee.com/",
                  alts: [],
                  httpStatus: 200,
                },
                {
                  url:
                    "https://medium.com/@anuragbhattacharjee/social-media-multitasking-is-affecting-our-brain-976f98f72f2e",
                  alts: [
                    { type: 2, url: "medium://p/976f98f72f2e" },
                    { type: 3, url: "medium://p/976f98f72f2e" },
                  ],
                  httpStatus: 200,
                },
                {
                  url: "https://medium.com/@anuragbhattacharjee",
                  alts: [
                    { type: 2, url: "medium://@anuragbhattacharjee" },
                    { type: 3, url: "medium://@anuragbhattacharjee" },
                  ],
                  httpStatus: 200,
                },
                {
                  url:
                    "https://medium.com/analytics-vidhya/why-we-should-never-update-react-state-directly-c1b794fac59b",
                  alts: [
                    { type: 3, url: "medium://p/c1b794fac59b" },
                    { type: 2, url: "medium://p/c1b794fac59b" },
                  ],
                  httpStatus: 200,
                },
              ],
              version: "0.3",
              generatedAt: 1593520901494,
            },
            isLockedPreviewOnly: false,
            metaDescription: "",
            totalClapCount: 56,
            sectionCount: 2,
            readingList: 0,
            topics: [
              {
                topicId: "63c6f1f93ee",
                slug: "javascript",
                createdAt: 1493945704961,
                deletedAt: 0,
                image: {
                  id: "1*5UyyDJ1ESD11yQNd1UVAaQ@2x.jpeg",
                  originalWidth: 5472,
                  originalHeight: 3648,
                },
                name: "Javascript",
                description: "An ode to code.",
                relatedTopics: [],
                visibility: 1,
                relatedTags: [],
                relatedTopicIds: [],
                seoTitle: "Javascript News and Articles — Medium",
                type: "Topic",
              },
            ],
          },
          coverless: true,
          slug: "diy-music-player-with-react-js",
          translationSourcePostId: "",
          translationSourceCreatorId: "",
          isApprovedTranslation: false,
          inResponseToPostId: "",
          inResponseToRemovedAt: 0,
          isTitleSynthesized: false,
          allowResponses: true,
          importedUrl: "",
          importedPublishedAt: 0,
          visibility: 0,
          uniqueSlug: "diy-music-player-with-react-js-309d63cfbcba",
          previewContent: {
            bodyModel: {
              paragraphs: [
                {
                  name: "previewImage",
                  type: 4,
                  text: "",
                  layout: 10,
                  metadata: {
                    id: "1*p3aXdoG0I41Dvx0bPco9MQ.png",
                    originalWidth: 1917,
                    originalHeight: 943,
                    isFeatured: true,
                  },
                },
                {
                  name: "b34d",
                  type: 3,
                  text: "DIY Music Player with React js 📻",
                  markups: [],
                  alignment: 1,
                },
                {
                  name: "ac8d",
                  type: 13,
                  text:
                    "Distraction-free music player to search for music from youtube and…",
                  markups: [],
                  alignment: 1,
                },
              ],
              sections: [{ startIndex: 0 }],
            },
            isFullContent: false,
            subtitle:
              "Distraction-free music player to search for music from youtube and play the audio.",
          },
          license: 0,
          inResponseToMediaResourceId: "",
          canonicalUrl: "",
          approvedHomeCollectionId: "",
          newsletterId: "",
          webCanonicalUrl: "",
          mediumUrl: "",
          migrationId: "",
          notifyFollowers: true,
          notifyTwitter: false,
          notifyFacebook: false,
          responseHiddenOnParentPostAt: 0,
          isSeries: false,
          isSubscriptionLocked: false,
          seriesLastAppendedAt: 0,
          audioVersionDurationSec: 0,
          sequenceId: "",
          isEligibleForRevenue: false,
          isBlockedFromHightower: false,
          deletedAt: 0,
          lockedPostSource: 0,
          hightowerMinimumGuaranteeStartsAt: 0,
          hightowerMinimumGuaranteeEndsAt: 0,
          featureLockRequestAcceptedAt: 0,
          mongerRequestType: 1,
          layerCake: 0,
          socialTitle: "",
          socialDek: "",
          editorialPreviewTitle: "",
          editorialPreviewDek: "",
          curationEligibleAt: 1593520895875,
          isProxyPost: false,
          proxyPostFaviconUrl: "",
          proxyPostProviderName: "",
          proxyPostType: 0,
          isSuspended: false,
          isLimitedState: false,
          seoTitle: "",
          previewContent2: {
            bodyModel: {
              paragraphs: [
                {
                  name: "b34d",
                  type: 3,
                  text: "DIY Music Player with React js 📻",
                  markups: [],
                },
                {
                  name: "ac8d",
                  type: 13,
                  text:
                    "Distraction-free music player to search for music from youtube and play the audio.",
                  markups: [],
                },
                { name: "0a42", type: 13, text: "Motivation", markups: [] },
                {
                  name: "24db",
                  type: 1,
                  text:
                    "Covid-19 has changed a lot of practices. We are getting used to working from home. Whereas it’s very convenient in some ways but it’s also very easy to get distracted when working alone. Especially when an addiction like social media is always poking us to keep looking at it.",
                  markups: [],
                },
                {
                  name: "3a4e",
                  type: 1,
                  text: "So…",
                  markups: [
                    {
                      type: 3,
                      start: 330,
                      end: 383,
                      href:
                        "https://medium.com/@anuragbhattacharjee/social-media-multitasking-is-affecting-our-brain-976f98f72f2e",
                      title: "",
                      rel: "",
                      anchorType: 0,
                    },
                    { type: 1, start: 330, end: 383 },
                  ],
                },
              ],
              sections: [{ startIndex: 0 }],
            },
            isFullContent: false,
            subtitle:
              "Distraction-free music player to search for music from youtube and play the audio.",
          },
          cardType: 0,
          isDistributionAlertDismissed: false,
          isShortform: false,
          shortformType: 0,
          responsesLocked: false,
          isLockedResponse: false,
          type: "Post",
        },
        "976f98f72f2e": {
          id: "976f98f72f2e",
          versionId: "3ac505e14334",
          creatorId: "9a619946f362",
          homeCollectionId: "",
          title: "Social media & multitasking is affecting our brain 🧠",
          detectedLanguage: "en",
          latestVersion: "3ac505e14334",
          latestPublishedVersion: "3ac505e14334",
          hasUnpublishedEdits: false,
          latestRev: 898,
          createdAt: 1591266812436,
          updatedAt: 1593520447730,
          acceptedAt: 0,
          firstPublishedAt: 1591274901757,
          latestPublishedAt: 1593520447453,
          vote: false,
          experimentalCss: "",
          displayAuthor: "",
          content: {
            subtitle:
              "Did you ever feel that your phone is vibrating while it actually wasn’t? Or did you just hear a sound of notification when there was…",
            postDisplay: { coverless: true },
          },
          virtuals: {
            allowNotes: true,
            previewImage: {
              imageId: "1*LIihcQsDGI3-3JNxFxIvKA.jpeg",
              filter: "",
              backgroundSize: "",
              originalWidth: 398,
              originalHeight: 398,
              strategy: "resample",
              height: 0,
              width: 0,
            },
            wordCount: 1402,
            imageCount: 4,
            readingTime: 5.990566037735849,
            subtitle:
              "Did you ever feel that your phone is vibrating while it actually wasn’t? Or did you just hear a sound of notification when there was…",
            userPostRelation: {
              userId: "9a619946f362",
              postId: "976f98f72f2e",
              readAt: 1593520775486,
              readLaterAddedAt: 0,
              votedAt: 0,
              collaboratorAddedAt: 0,
              notesAddedAt: 0,
              subscribedAt: 0,
              lastReadSectionName: "1fec",
              lastReadVersionId: "5aef0edc3944",
              lastReadAt: 1591379665072,
              lastReadParagraphName: "6ae1",
              lastReadPercentage: 0,
              viewedAt: 1593520775486,
              presentedCountInResponseManagement: 0,
              clapCount: 0,
              seriesUpdateNotifsOptedInAt: 0,
              queuedAt: 0,
              seriesFirstViewedAt: 0,
              presentedCountInStream: 4,
              seriesLastViewedAt: 0,
              audioProgressSec: 0,
            },
            usersBySocialRecommends: [],
            noIndex: false,
            recommends: 6,
            isBookmarked: false,
            tags: [
              {
                slug: "social-media",
                name: "Social Media",
                postCount: 238752,
                metadata: {
                  postCount: 238752,
                  coverImage: { id: "1*uOkczl-rzzPqbQTw6PSFYw.jpeg" },
                },
                type: "Tag",
              },
              {
                slug: "effect",
                name: "Effect",
                postCount: 323,
                metadata: {
                  postCount: 323,
                  coverImage: {
                    id: "1*0FeHl5txunXAsspjlkLPkA.jpeg",
                    originalWidth: 878,
                    originalHeight: 527,
                    isFeatured: true,
                  },
                },
                type: "Tag",
              },
              {
                slug: "social-media-effects",
                name: "Social Media Effects",
                postCount: 14,
                metadata: {
                  postCount: 14,
                  coverImage: {
                    id: "1*nXvbtOY9TCGcNOxqPLgR1Q.jpeg",
                    originalWidth: 626,
                    originalHeight: 626,
                    isFeatured: true,
                  },
                },
                type: "Tag",
              },
              {
                slug: "pomodoro-technique",
                name: "Pomodoro Technique",
                postCount: 1091,
                metadata: {
                  postCount: 1091,
                  coverImage: {
                    id: "1*-gZawMvSWjeIF21YIGq-mg.jpeg",
                    originalWidth: 6000,
                    originalHeight: 4000,
                    isFeatured: true,
                  },
                },
                type: "Tag",
              },
              {
                slug: "depression",
                name: "Depression",
                postCount: 52511,
                metadata: {
                  postCount: 52511,
                  coverImage: {
                    id: "1*GoxQsGM5JKMyvbwrtVxJZw.jpeg",
                    originalWidth: 1920,
                    originalHeight: 1080,
                    isFeatured: true,
                    focusPercentX: 66,
                    focusPercentY: 60,
                  },
                },
                type: "Tag",
              },
            ],
            socialRecommendsCount: 0,
            responsesCreatedCount: 1,
            links: {
              entries: [
                {
                  url: "https://www.linkedin.com/in/anuragbhattacharjee/",
                  alts: [],
                  httpStatus: 999,
                },
                {
                  url: "https://medium.com/@anuragbhattacharjee/",
                  alts: [
                    { type: 2, url: "medium://@anuragbhattacharjee" },
                    { type: 3, url: "medium://@anuragbhattacharjee" },
                  ],
                  httpStatus: 200,
                },
                {
                  url: "http://webpod.anuragbhattacharjee.com/",
                  alts: [],
                  httpStatus: 200,
                },
                {
                  url: "http://anuragbhattacharjee.com",
                  alts: [],
                  httpStatus: 200,
                },
                {
                  url: "https://medium.com/@anuragbhattacharjee",
                  alts: [
                    { type: 2, url: "medium://@anuragbhattacharjee" },
                    { type: 3, url: "medium://@anuragbhattacharjee" },
                  ],
                  httpStatus: 200,
                },
              ],
              version: "0.3",
              generatedAt: 1593520448409,
            },
            isLockedPreviewOnly: false,
            metaDescription: "",
            totalClapCount: 8,
            sectionCount: 7,
            readingList: 0,
            topics: [
              {
                topicId: "8a146bc21b28",
                slug: "productivity",
                createdAt: 1493922639062,
                deletedAt: 0,
                image: {
                  id: "1*Kl2y0nUvCgWSE8Qv0Et84Q@2x.jpeg",
                  originalWidth: 4096,
                  originalHeight: 2731,
                },
                name: "Productivity",
                description: "Working with purpose.",
                relatedTopics: [],
                visibility: 1,
                relatedTags: [],
                relatedTopicIds: [],
                seoTitle: "Articles About Productivity — Medium",
                type: "Topic",
              },
            ],
          },
          coverless: true,
          slug: "social-media-multitasking-is-affecting-our-brain",
          translationSourcePostId: "",
          translationSourceCreatorId: "",
          isApprovedTranslation: false,
          inResponseToPostId: "",
          inResponseToRemovedAt: 0,
          isTitleSynthesized: false,
          allowResponses: true,
          importedUrl: "",
          importedPublishedAt: 0,
          visibility: 0,
          uniqueSlug:
            "social-media-multitasking-is-affecting-our-brain-976f98f72f2e",
          previewContent: {
            bodyModel: {
              paragraphs: [
                {
                  name: "previewImage",
                  type: 4,
                  text: "",
                  layout: 10,
                  metadata: {
                    id: "1*LIihcQsDGI3-3JNxFxIvKA.jpeg",
                    originalWidth: 398,
                    originalHeight: 398,
                    isFeatured: true,
                  },
                },
                {
                  name: "d082",
                  type: 3,
                  text: "Social media & multitasking is affecting our brain 🧠",
                  markups: [],
                  alignment: 1,
                },
              ],
              sections: [{ startIndex: 0 }],
            },
            isFullContent: false,
            subtitle:
              "Did you ever feel that your phone is vibrating while it actually wasn’t? Or did you just hear a sound of notification when there was…",
          },
          license: 0,
          inResponseToMediaResourceId: "",
          canonicalUrl: "",
          approvedHomeCollectionId: "",
          newsletterId: "",
          webCanonicalUrl: "",
          mediumUrl: "",
          migrationId: "",
          notifyFollowers: true,
          notifyTwitter: false,
          notifyFacebook: false,
          responseHiddenOnParentPostAt: 0,
          isSeries: false,
          isSubscriptionLocked: false,
          seriesLastAppendedAt: 0,
          audioVersionDurationSec: 0,
          sequenceId: "",
          isEligibleForRevenue: false,
          isBlockedFromHightower: false,
          deletedAt: 0,
          lockedPostSource: 0,
          hightowerMinimumGuaranteeStartsAt: 0,
          hightowerMinimumGuaranteeEndsAt: 0,
          featureLockRequestAcceptedAt: 0,
          mongerRequestType: 1,
          layerCake: 0,
          socialTitle: "",
          socialDek: "",
          editorialPreviewTitle: "",
          editorialPreviewDek: "",
          curationEligibleAt: 1591274900523,
          isProxyPost: false,
          proxyPostFaviconUrl: "",
          proxyPostProviderName: "",
          proxyPostType: 0,
          isSuspended: false,
          isLimitedState: false,
          seoTitle: "",
          previewContent2: {
            bodyModel: {
              paragraphs: [
                {
                  name: "d082",
                  type: 3,
                  text: "Social media & multitasking is affecting our brain 🧠",
                  markups: [],
                },
                {
                  name: "3bb0",
                  type: 1,
                  text:
                    "Did you ever feel that your phone is vibrating while it actually wasn’t? Or did you just hear a sound of notification when there was nothing? Do you compulsively just pick your phone and lock-unlock it in the middle of thinking something, just to discover that you are staring at…",
                  markups: [],
                },
              ],
              sections: [{ startIndex: 0 }],
            },
            isFullContent: false,
            subtitle:
              "Did you ever feel that your phone is vibrating while it actually wasn’t? Or did you just hear a sound of notification when there was…",
          },
          cardType: 0,
          isDistributionAlertDismissed: false,
          isShortform: false,
          shortformType: 0,
          responsesLocked: false,
          isLockedResponse: false,
          type: "Post",
        },
        "22ca582493f6": {
          id: "22ca582493f6",
          versionId: "658ed15135fb",
          creatorId: "9a619946f362",
          homeCollectionId: "5e5bef33608a",
          title: "A brief introduction to AI",
          detectedLanguage: "en",
          latestVersion: "658ed15135fb",
          latestPublishedVersion: "658ed15135fb",
          hasUnpublishedEdits: false,
          latestRev: 1159,
          createdAt: 1589355067993,
          updatedAt: 1593520542659,
          acceptedAt: 0,
          firstPublishedAt: 1589368311164,
          latestPublishedAt: 1593520542492,
          vote: false,
          experimentalCss: "",
          displayAuthor: "",
          content: {
            subtitle:
              "AI and Machine Learning are two buzz words that everyone hears almost every day. But when we see the job requirements there is a miss…",
            postDisplay: { coverless: true },
          },
          virtuals: {
            statusForCollection: "APPROVED",
            allowNotes: true,
            previewImage: {
              imageId: "1*Mma7FozWqBSbVcC5dSoXrg.png",
              filter: "",
              backgroundSize: "",
              originalWidth: 800,
              originalHeight: 600,
              strategy: "resample",
              height: 0,
              width: 0,
            },
            wordCount: 1820,
            imageCount: 16,
            readingTime: 8.417924528301887,
            subtitle:
              "AI and Machine Learning are two buzz words that everyone hears almost every day. But when we see the job requirements there is a miss…",
            userPostRelation: {
              userId: "9a619946f362",
              postId: "22ca582493f6",
              readAt: 1595332913946,
              readLaterAddedAt: 0,
              votedAt: 0,
              collaboratorAddedAt: 0,
              notesAddedAt: 0,
              subscribedAt: 0,
              lastReadSectionName: "b532",
              lastReadVersionId: "ded9da54651",
              lastReadAt: 1589877566054,
              lastReadParagraphName: "09f5",
              lastReadPercentage: 0,
              viewedAt: 1595332848557,
              presentedCountInResponseManagement: 0,
              clapCount: 0,
              seriesUpdateNotifsOptedInAt: 0,
              queuedAt: 0,
              seriesFirstViewedAt: 0,
              presentedCountInStream: 7,
              seriesLastViewedAt: 0,
              audioProgressSec: 0,
            },
            publishedInCount: 1,
            usersBySocialRecommends: [],
            noIndex: false,
            recommends: 2,
            isBookmarked: false,
            tags: [
              {
                slug: "artificial-intelligence",
                name: "Artificial Intelligence",
                postCount: 123174,
                metadata: {
                  postCount: 123174,
                  coverImage: { id: "1*gAn_BSffVBcwCIR6bDgK1g.jpeg" },
                },
                type: "Tag",
              },
              {
                slug: "machine-learning",
                name: "Machine Learning",
                postCount: 121765,
                metadata: {
                  postCount: 121765,
                  coverImage: {
                    id: "1*IswU0VXXt-dzO4eacEE17g.jpeg",
                    originalWidth: 4206,
                    originalHeight: 2804,
                    isFeatured: true,
                  },
                },
                type: "Tag",
              },
              {
                slug: "technology",
                name: "Technology",
                postCount: 297732,
                metadata: {
                  postCount: 297732,
                  coverImage: { id: "1*yH2cmH1uhoFpR7HIseOAsw.jpeg" },
                },
                type: "Tag",
              },
              {
                slug: "ai",
                name: "AI",
                postCount: 52312,
                metadata: {
                  postCount: 52312,
                  coverImage: {
                    id: "1*LYSBdJHi0c1_SV-EhXZFGQ.png",
                    originalWidth: 2315,
                    originalHeight: 1013,
                    isFeatured: true,
                  },
                },
                type: "Tag",
              },
              {
                slug: "deep-learning",
                name: "Deep Learning",
                postCount: 31118,
                metadata: {
                  postCount: 31118,
                  coverImage: {
                    id: "1*LYSBdJHi0c1_SV-EhXZFGQ.png",
                    originalWidth: 2315,
                    originalHeight: 1013,
                    isFeatured: true,
                  },
                },
                type: "Tag",
              },
            ],
            socialRecommendsCount: 0,
            responsesCreatedCount: 1,
            links: {
              entries: [
                {
                  url: "https://www.linkedin.com/in/anuragbhattacharjee/",
                  alts: [],
                  httpStatus: 999,
                },
                {
                  url: "https://en.wikipedia.org/wiki/Dartmouth_workshop",
                  alts: [],
                  httpStatus: 200,
                },
                {
                  url: "https://en.wikipedia.org/wiki/Geoffrey_Hinton",
                  alts: [],
                  httpStatus: 200,
                },
                {
                  url: "https://en.wikipedia.org/wiki/Garry_Kasparov",
                  alts: [],
                  httpStatus: 200,
                },
                { url: "https://upscri.be/8f5f8b", alts: [], httpStatus: 200 },
                {
                  url: "https://pathmind.com/wiki/symbolic-reasoning",
                  alts: [],
                  httpStatus: 200,
                },
                {
                  url: "http://BecomingHuman.ai",
                  alts: [
                    { type: 2, url: "medium://becoming-human" },
                    { type: 3, url: "medium://becoming-human" },
                  ],
                  httpStatus: 200,
                },
                {
                  url: "https://www.youtube.com/watch?v=l9RWTMNnvi4&t=126s",
                  alts: [],
                  httpStatus: 429,
                },
                {
                  url:
                    "https://hackernoon.com/the-ai-hierarchy-of-needs-18f111fcc007",
                  alts: [],
                  httpStatus: 200,
                },
                {
                  url: "https://www.youtube.com/watch?v=ad79nYk2keg",
                  alts: [],
                  httpStatus: 429,
                },
                {
                  url: "https://www.youtube.com/watch?v=xC-c7E5PK0Y",
                  alts: [],
                  httpStatus: 429,
                },
                {
                  url: "https://www.youtube.com/watch?v=aircAruvnKk&t=658s",
                  alts: [],
                  httpStatus: 429,
                },
                {
                  url: "https://becominghuman.ai/write-for-us-48270209de63",
                  alts: [
                    { type: 3, url: "medium://p/48270209de63" },
                    { type: 2, url: "medium://p/48270209de63" },
                  ],
                  httpStatus: 200,
                },
                {
                  url: "https://www.youtube.com/watch?v=2ePf9rue1Ao&t=190s",
                  alts: [],
                  httpStatus: 429,
                },
                {
                  url: "http://anuragbhattacharjee.com",
                  alts: [],
                  httpStatus: 200,
                },
                {
                  url:
                    "https://medium.com/@rchang/a-beginners-guide-to-data-engineering-part-i-4227c5c457d7",
                  alts: [
                    { type: 3, url: "medium://p/4227c5c457d7" },
                    { type: 2, url: "medium://p/4227c5c457d7" },
                  ],
                  httpStatus: 200,
                },
                {
                  url:
                    "https://becominghuman.ai/artificial-intelligence-communities-c305f28e674c",
                  alts: [
                    { type: 3, url: "medium://p/c305f28e674c" },
                    { type: 2, url: "medium://p/c305f28e674c" },
                  ],
                  httpStatus: 200,
                },
                {
                  url:
                    "https://dribbble.com/shots/6789211-Document-flow-and-processing",
                  alts: [],
                  httpStatus: 200,
                },
                {
                  url:
                    "https://becominghuman.ai/ai-for-cfd-intro-part-1-d1184936fc47",
                  alts: [
                    { type: 3, url: "medium://p/d1184936fc47" },
                    { type: 2, url: "medium://p/d1184936fc47" },
                  ],
                  httpStatus: 200,
                },
                {
                  url: "https://www.youtube.com/watch?v=r8ZlKx2d2dA",
                  alts: [],
                  httpStatus: 429,
                },
                {
                  url: "https://www.youtube.com/watch?v=SN2BZswEWUA&t=809s",
                  alts: [],
                  httpStatus: 429,
                },
                {
                  url:
                    "https://becominghuman.ai/machine-learning-system-design-f2f4018f2f8",
                  alts: [
                    { type: 3, url: "medium://p/f2f4018f2f8" },
                    { type: 2, url: "medium://p/f2f4018f2f8" },
                  ],
                  httpStatus: 200,
                },
                {
                  url: "https://medium.com/@anuragbhattacharjee",
                  alts: [
                    { type: 2, url: "medium://@anuragbhattacharjee" },
                    { type: 3, url: "medium://@anuragbhattacharjee" },
                  ],
                  httpStatus: 200,
                },
                {
                  url:
                    "https://becominghuman.ai/real-vs-fake-tweet-detection-using-a-bert-transformer-model-in-few-lines-of-code-ccc33ecb1a2",
                  alts: [
                    { type: 3, url: "medium://p/ccc33ecb1a2" },
                    { type: 2, url: "medium://p/ccc33ecb1a2" },
                  ],
                  httpStatus: 200,
                },
                {
                  url:
                    "https://towardsdatascience.com/data-engineer-vs-data-scientist-bc8dab5ac124",
                  alts: [
                    { type: 2, url: "medium://p/bc8dab5ac124" },
                    { type: 3, url: "medium://p/bc8dab5ac124" },
                  ],
                  httpStatus: 200,
                },
                {
                  url:
                    "https://bdtechtalks.com/2019/02/15/what-is-deep-learning-neural-networks/",
                  alts: [
                    {
                      type: 1,
                      url:
                        "https://cdn.ampproject.org/c/s/bdtechtalks.com/2019/02/15/what-is-deep-learning-neural-networks/amp/",
                    },
                  ],
                  httpStatus: 200,
                },
                {
                  url:
                    "https://bdtechtalks.com/2019/08/05/what-is-artificial-neural-network-ann/",
                  alts: [
                    {
                      type: 1,
                      url:
                        "https://cdn.ampproject.org/c/s/bdtechtalks.com/2019/08/05/what-is-artificial-neural-network-ann/amp/",
                    },
                  ],
                  httpStatus: 200,
                },
                {
                  url:
                    "https://becominghuman.ai/using-artificial-intelligence-to-detect-covid-19-6fcd9857b93f",
                  alts: [
                    { type: 3, url: "medium://p/6fcd9857b93f" },
                    { type: 2, url: "medium://p/6fcd9857b93f" },
                  ],
                  httpStatus: 200,
                },
                {
                  url:
                    "https://bdtechtalks.com/2019/11/18/what-is-symbolic-artificial-intelligence/",
                  alts: [
                    {
                      type: 1,
                      url:
                        "https://cdn.ampproject.org/c/s/bdtechtalks.com/2019/11/18/what-is-symbolic-artificial-intelligence/amp/",
                    },
                  ],
                  httpStatus: 200,
                },
                {
                  url:
                    "https://aijobsboard.com/?fbclid=IwAR0GcsLs8A6CFM-fynrZMM3sTgo_Zpbto2CjglJm0Dmi6otC6YWc7CDFpQk",
                  alts: [],
                  httpStatus: 0,
                },
              ],
              version: "0.3",
              generatedAt: 1593520552692,
            },
            isLockedPreviewOnly: false,
            metaDescription: "",
            totalClapCount: 51,
            sectionCount: 5,
            readingList: 0,
            topics: [
              {
                topicId: "1af65db9c2f8",
                slug: "artificial-intelligence",
                createdAt: 1487916832419,
                deletedAt: 0,
                image: {
                  id: "1*A28aHchbaA8zNVXraBq0Ug@2x.jpeg",
                  originalWidth: 4866,
                  originalHeight: 3244,
                },
                name: "Artificial Intelligence",
                description: "Born to be bot.",
                relatedTopics: [],
                visibility: 1,
                relatedTags: [],
                relatedTopicIds: [],
                seoTitle:
                  "AI News and Artificial Intelligence Articles — Medium",
                type: "Topic",
              },
            ],
          },
          coverless: true,
          slug: "a-brief-introduction-to-ai",
          translationSourcePostId: "",
          translationSourceCreatorId: "",
          isApprovedTranslation: false,
          inResponseToPostId: "",
          inResponseToRemovedAt: 0,
          isTitleSynthesized: false,
          allowResponses: true,
          importedUrl: "",
          importedPublishedAt: 0,
          visibility: 0,
          uniqueSlug: "a-brief-introduction-to-ai-22ca582493f6",
          previewContent: {
            bodyModel: {
              paragraphs: [
                {
                  name: "previewImage",
                  type: 4,
                  text: "",
                  layout: 10,
                  metadata: {
                    id: "1*Mma7FozWqBSbVcC5dSoXrg.png",
                    originalWidth: 800,
                    originalHeight: 600,
                    isFeatured: true,
                  },
                },
                {
                  name: "22f4",
                  type: 3,
                  text: "A brief introduction to AI",
                  markups: [],
                  alignment: 1,
                },
                {
                  name: "44f4",
                  type: 1,
                  text:
                    "AI and Machine Learning are two buzz words that everyone hears almost every day. But when we see the job…",
                  markups: [
                    {
                      type: 3,
                      start: 0,
                      end: 2,
                      href: "http://BecomingHuman.ai",
                      title: "",
                      rel: "",
                      anchorType: 0,
                    },
                    {
                      type: 3,
                      start: 7,
                      end: 23,
                      href: "http://BecomingHuman.ai",
                      title: "",
                      rel: "",
                      anchorType: 0,
                    },
                  ],
                  alignment: 1,
                },
              ],
              sections: [{ startIndex: 0 }],
            },
            isFullContent: false,
            subtitle:
              "AI and Machine Learning are two buzz words that everyone hears almost every day. But when we see the job requirements there is a miss…",
          },
          license: 0,
          inResponseToMediaResourceId: "",
          canonicalUrl: "",
          approvedHomeCollectionId: "5e5bef33608a",
          newsletterId: "",
          webCanonicalUrl: "",
          mediumUrl: "",
          migrationId: "",
          notifyFollowers: true,
          notifyTwitter: false,
          notifyFacebook: false,
          responseHiddenOnParentPostAt: 0,
          isSeries: false,
          isSubscriptionLocked: false,
          seriesLastAppendedAt: 0,
          audioVersionDurationSec: 0,
          sequenceId: "",
          isEligibleForRevenue: false,
          isBlockedFromHightower: false,
          deletedAt: 0,
          lockedPostSource: 0,
          hightowerMinimumGuaranteeStartsAt: 0,
          hightowerMinimumGuaranteeEndsAt: 0,
          featureLockRequestAcceptedAt: 0,
          mongerRequestType: 1,
          layerCake: 0,
          socialTitle: "",
          socialDek: "",
          editorialPreviewTitle: "",
          editorialPreviewDek: "",
          curationEligibleAt: 1589368309769,
          isProxyPost: false,
          proxyPostFaviconUrl: "",
          proxyPostProviderName: "",
          proxyPostType: 0,
          isSuspended: false,
          isLimitedState: false,
          seoTitle: "",
          previewContent2: {
            bodyModel: {
              paragraphs: [
                {
                  name: "2fa2",
                  type: 4,
                  text: "Source",
                  markups: [
                    {
                      type: 3,
                      start: 0,
                      end: 6,
                      href:
                        "https://dribbble.com/shots/6789211-Document-flow-and-processing",
                      title: "",
                      rel: "noopener",
                      anchorType: 0,
                    },
                  ],
                  layout: 1,
                  metadata: {
                    id: "1*Mma7FozWqBSbVcC5dSoXrg.png",
                    originalWidth: 800,
                    originalHeight: 600,
                    isFeatured: true,
                  },
                },
                {
                  name: "22f4",
                  type: 3,
                  text: "A brief introduction to AI",
                  markups: [],
                },
                {
                  name: "44f4",
                  type: 1,
                  text:
                    "AI and Machine Learning are two buzz words that everyone hears almost every day. But when we see the job requirements there is a miss alignment between what we actually see in the news and what companies require. Why is that? Let’s get it by learning what lies under the…",
                  markups: [
                    {
                      type: 3,
                      start: 0,
                      end: 2,
                      href: "http://BecomingHuman.ai",
                      title: "",
                      rel: "",
                      anchorType: 0,
                    },
                    {
                      type: 3,
                      start: 7,
                      end: 23,
                      href: "http://BecomingHuman.ai",
                      title: "",
                      rel: "",
                      anchorType: 0,
                    },
                  ],
                },
              ],
              sections: [{ startIndex: 0 }],
            },
            isFullContent: false,
            subtitle:
              "AI and Machine Learning are two buzz words that everyone hears almost every day. But when we see the job requirements there is a miss…",
          },
          cardType: 0,
          isDistributionAlertDismissed: false,
          isShortform: false,
          shortformType: 0,
          responsesLocked: false,
          isLockedResponse: false,
          type: "Post",
        },
        c1b794fac59b: {
          id: "c1b794fac59b",
          versionId: "158b9822905c",
          creatorId: "9a619946f362",
          homeCollectionId: "7219b4dc6c4c",
          title: "Why we should never update React State directly",
          detectedLanguage: "en",
          latestVersion: "158b9822905c",
          latestPublishedVersion: "158b9822905c",
          hasUnpublishedEdits: false,
          latestRev: 448,
          createdAt: 1580106518787,
          updatedAt: 1593520656212,
          acceptedAt: 0,
          firstPublishedAt: 1580114890235,
          latestPublishedAt: 1593520655970,
          vote: false,
          experimentalCss: "",
          displayAuthor: "",
          content: {
            subtitle:
              "Whoever worked or learned a bit of React probably knows that we should never change or update the state directly. Rather we should always…",
            postDisplay: { coverless: true },
          },
          virtuals: {
            statusForCollection: "APPROVED",
            allowNotes: true,
            previewImage: {
              imageId: "1*4jNs9MbPRc9_Lvxqy6S0ww.png",
              filter: "",
              backgroundSize: "",
              originalWidth: 693,
              originalHeight: 558,
              strategy: "resample",
              height: 0,
              width: 0,
            },
            wordCount: 664,
            imageCount: 3,
            readingTime: 3.0556603773584907,
            subtitle:
              "Whoever worked or learned a bit of React probably knows that we should never change or update the state directly. Rather we should always…",
            userPostRelation: {
              userId: "9a619946f362",
              postId: "c1b794fac59b",
              readAt: 1595482933854,
              readLaterAddedAt: 0,
              votedAt: 0,
              collaboratorAddedAt: 0,
              notesAddedAt: 0,
              subscribedAt: 0,
              lastReadSectionName: "3599",
              lastReadVersionId: "158b9822905c",
              lastReadAt: 1595384578627,
              lastReadParagraphName: "a4e4",
              lastReadPercentage: 0,
              viewedAt: 1595482913964,
              presentedCountInResponseManagement: 0,
              clapCount: 0,
              seriesUpdateNotifsOptedInAt: 0,
              queuedAt: 0,
              seriesFirstViewedAt: 0,
              presentedCountInStream: 17,
              seriesLastViewedAt: 0,
              audioProgressSec: 0,
            },
            publishedInCount: 1,
            usersBySocialRecommends: [],
            noIndex: false,
            recommends: 1,
            isBookmarked: false,
            tags: [
              {
                slug: "react",
                name: "React",
                postCount: 41396,
                metadata: {
                  postCount: 41396,
                  coverImage: {
                    id: "0*TlI4gXBrO-_pG2mg",
                    originalWidth: 5994,
                    originalHeight: 3996,
                    isFeatured: true,
                    unsplashPhotoId: "JEqAtLhffRY",
                  },
                },
                type: "Tag",
              },
              {
                slug: "react-hook",
                name: "React Hook",
                postCount: 1426,
                metadata: {
                  postCount: 1426,
                  coverImage: {
                    id: "1*FZG3T1_HeYFsPR395xppHQ.jpeg",
                    originalWidth: 2683,
                    originalHeight: 1102,
                    isFeatured: true,
                  },
                },
                type: "Tag",
              },
              {
                slug: "js",
                name: "Js",
                postCount: 2409,
                metadata: {
                  postCount: 2409,
                  coverImage: {
                    id: "1*ofDaO2cdRLIJtJJBxjRC0A.png",
                    originalWidth: 800,
                    originalHeight: 707,
                    isFeatured: true,
                  },
                },
                type: "Tag",
              },
              {
                slug: "reactjs",
                name: "Reactjs",
                postCount: 7772,
                metadata: {
                  postCount: 7772,
                  coverImage: {
                    id: "1*lhhEj8dRWk2ZpnMufW3nJQ.png",
                    originalWidth: 2240,
                    originalHeight: 1260,
                    isFeatured: true,
                  },
                },
                type: "Tag",
              },
              {
                slug: "programming",
                name: "Programming",
                postCount: 153619,
                metadata: {
                  postCount: 153619,
                  coverImage: {
                    id: "1*uogTxM0a-lVa__VkjFBjew.jpeg",
                    originalWidth: 5472,
                    originalHeight: 3078,
                    isFeatured: true,
                  },
                },
                type: "Tag",
              },
            ],
            socialRecommendsCount: 0,
            responsesCreatedCount: 1,
            links: {
              entries: [
                {
                  url: "http://anuragbhattacharjee.com",
                  alts: [],
                  httpStatus: 200,
                },
                {
                  url: "https://medium.com/@anuragbhattacharjee",
                  alts: [
                    { type: 2, url: "medium://@anuragbhattacharjee" },
                    { type: 3, url: "medium://@anuragbhattacharjee" },
                  ],
                  httpStatus: 200,
                },
                {
                  url: "https://www.linkedin.com/in/anuragbhattacharjee/",
                  alts: [],
                  httpStatus: 999,
                },
              ],
              version: "0.3",
              generatedAt: 1593520656107,
            },
            isLockedPreviewOnly: false,
            metaDescription: "",
            totalClapCount: 18,
            sectionCount: 2,
            readingList: 0,
            topics: [
              {
                topicId: "63c6f1f93ee",
                slug: "javascript",
                createdAt: 1493945704961,
                deletedAt: 0,
                image: {
                  id: "1*5UyyDJ1ESD11yQNd1UVAaQ@2x.jpeg",
                  originalWidth: 5472,
                  originalHeight: 3648,
                },
                name: "Javascript",
                description: "An ode to code.",
                relatedTopics: [],
                visibility: 1,
                relatedTags: [],
                relatedTopicIds: [],
                seoTitle: "Javascript News and Articles — Medium",
                type: "Topic",
              },
            ],
          },
          coverless: true,
          slug: "why-we-should-never-update-react-state-directly",
          translationSourcePostId: "",
          translationSourceCreatorId: "",
          isApprovedTranslation: false,
          inResponseToPostId: "",
          inResponseToRemovedAt: 0,
          isTitleSynthesized: false,
          allowResponses: true,
          importedUrl: "",
          importedPublishedAt: 0,
          visibility: 0,
          uniqueSlug:
            "why-we-should-never-update-react-state-directly-c1b794fac59b",
          previewContent: {
            bodyModel: {
              paragraphs: [
                {
                  name: "previewImage",
                  type: 4,
                  text: "",
                  layout: 10,
                  metadata: {
                    id: "1*4jNs9MbPRc9_Lvxqy6S0ww.png",
                    originalWidth: 693,
                    originalHeight: 558,
                    isFeatured: true,
                  },
                },
                {
                  name: "a4e4",
                  type: 3,
                  text: "Why we should never update React State directly!",
                  markups: [],
                  alignment: 1,
                },
              ],
              sections: [{ startIndex: 0 }],
            },
            isFullContent: false,
            subtitle:
              "Whoever worked or learned a bit of React probably knows that we should never change or update the state directly. Rather we should always…",
          },
          license: 0,
          inResponseToMediaResourceId: "",
          canonicalUrl: "",
          approvedHomeCollectionId: "7219b4dc6c4c",
          newsletterId: "",
          webCanonicalUrl: "",
          mediumUrl: "",
          migrationId: "",
          notifyFollowers: true,
          notifyTwitter: true,
          notifyFacebook: false,
          responseHiddenOnParentPostAt: 0,
          isSeries: false,
          isSubscriptionLocked: false,
          seriesLastAppendedAt: 0,
          audioVersionDurationSec: 0,
          sequenceId: "",
          isEligibleForRevenue: false,
          isBlockedFromHightower: false,
          deletedAt: 0,
          lockedPostSource: 0,
          hightowerMinimumGuaranteeStartsAt: 0,
          hightowerMinimumGuaranteeEndsAt: 0,
          featureLockRequestAcceptedAt: 0,
          mongerRequestType: 1,
          layerCake: 0,
          socialTitle: "",
          socialDek: "",
          editorialPreviewTitle: "",
          editorialPreviewDek: "",
          curationEligibleAt: 1580114888279,
          isProxyPost: false,
          proxyPostFaviconUrl: "",
          proxyPostProviderName: "",
          proxyPostType: 0,
          isSuspended: false,
          isLimitedState: false,
          seoTitle: "",
          previewContent2: {
            bodyModel: {
              paragraphs: [
                {
                  name: "a4e4",
                  type: 3,
                  text: "Why we should never update React State directly!",
                  markups: [],
                },
                {
                  name: "6b1d",
                  type: 1,
                  text:
                    "Whoever worked or learned a bit of React probably knows that we should never change or update the state directly. Rather we should always declare a new object const obj = {} and use this.setState({ obj }) and let react handle it, just like I did in the example below…",
                  markups: [
                    { type: 10, start: 159, end: 173 },
                    { type: 10, start: 182, end: 204 },
                    { type: 1, start: 35, end: 40 },
                  ],
                },
              ],
              sections: [{ startIndex: 0 }],
            },
            isFullContent: false,
            subtitle:
              "Whoever worked or learned a bit of React probably knows that we should never change or update the state directly. Rather we should always…",
          },
          cardType: 0,
          isDistributionAlertDismissed: false,
          isShortform: false,
          shortformType: 0,
          responsesLocked: false,
          isLockedResponse: false,
          type: "Post",
        },
        "555b765f2645": {
          id: "555b765f2645",
          versionId: "a5a1425c4254",
          creatorId: "9a619946f362",
          homeCollectionId: "",
          title: "Map!! Filter!! Reduce !!",
          detectedLanguage: "en",
          latestVersion: "a5a1425c4254",
          latestPublishedVersion: "a5a1425c4254",
          hasUnpublishedEdits: false,
          latestRev: 1140,
          createdAt: 1551853936246,
          updatedAt: 1593520713505,
          acceptedAt: 0,
          firstPublishedAt: 1552142976822,
          latestPublishedAt: 1593520713148,
          vote: false,
          experimentalCss: "",
          displayAuthor: "",
          content: { postDisplay: { coverless: true } },
          virtuals: {
            allowNotes: true,
            previewImage: {
              imageId: "1*d8YlI_rZ9RiGEQmnBjfbSQ.jpeg",
              filter: "",
              backgroundSize: "",
              originalWidth: 1023,
              originalHeight: 771,
              strategy: "resample",
              height: 0,
              width: 0,
            },
            wordCount: 1640,
            imageCount: 6,
            readingTime: 7.1386792452830194,
            subtitle: "",
            userPostRelation: {
              userId: "9a619946f362",
              postId: "555b765f2645",
              readAt: 1593520713235,
              readLaterAddedAt: 0,
              votedAt: 0,
              collaboratorAddedAt: 0,
              notesAddedAt: 0,
              subscribedAt: 0,
              lastReadSectionName: "d27a",
              lastReadVersionId: "4e74433414c0",
              lastReadAt: 1560145968231,
              lastReadParagraphName: "82cc",
              lastReadPercentage: 0.14,
              viewedAt: 1595482345046,
              presentedCountInResponseManagement: 0,
              clapCount: 0,
              seriesUpdateNotifsOptedInAt: 0,
              queuedAt: 0,
              seriesFirstViewedAt: 0,
              presentedCountInStream: 39,
              seriesLastViewedAt: 0,
              audioProgressSec: 0,
            },
            usersBySocialRecommends: [],
            noIndex: false,
            recommends: 5,
            isBookmarked: false,
            tags: [
              {
                slug: "javascript",
                name: "JavaScript",
                postCount: 137447,
                metadata: {
                  postCount: 137447,
                  coverImage: {
                    id: "0*3yj2i8k764xJZVT7",
                    originalWidth: 3000,
                    originalHeight: 2000,
                    isFeatured: true,
                    unsplashPhotoId: "xILBV0M7XDs",
                  },
                },
                type: "Tag",
              },
              {
                slug: "maps",
                name: "Maps",
                postCount: 6663,
                metadata: {
                  postCount: 6663,
                  coverImage: {
                    id: "1*Q4puRN8ssUBicuK0mSUXWQ.jpeg",
                    originalWidth: 1800,
                    originalHeight: 506,
                    isFeatured: true,
                  },
                },
                type: "Tag",
              },
              {
                slug: "filters",
                name: "Filters",
                postCount: 1099,
                metadata: {
                  postCount: 1099,
                  coverImage: {
                    id: "1*t46JciFvCtJWS6f4_NfuSg.png",
                    originalWidth: 1050,
                    originalHeight: 327,
                    isFeatured: true,
                  },
                },
                type: "Tag",
              },
              {
                slug: "reduce",
                name: "Reduce",
                postCount: 279,
                metadata: {
                  postCount: 279,
                  coverImage: {
                    id: "1*-Q4Vl5RxPsagdToQZphmiQ.jpeg",
                    originalWidth: 1900,
                    originalHeight: 1267,
                    isFeatured: true,
                  },
                },
                type: "Tag",
              },
              {
                slug: "functional-programming",
                name: "Functional Programming",
                postCount: 8230,
                metadata: {
                  postCount: 8230,
                  coverImage: {
                    id: "1*RPKQq3zYYddwJf9rSeQ4Dg.jpeg",
                    originalWidth: 1600,
                    originalHeight: 1085,
                  },
                },
                type: "Tag",
              },
            ],
            socialRecommendsCount: 0,
            responsesCreatedCount: 0,
            links: {
              entries: [
                {
                  url: "http://anuragbhattacharjee.com",
                  alts: [],
                  httpStatus: 200,
                },
                {
                  url: "https://medium.com/@anuragbhattacharjee",
                  alts: [
                    { type: 2, url: "medium://@anuragbhattacharjee" },
                    { type: 3, url: "medium://@anuragbhattacharjee" },
                  ],
                  httpStatus: 200,
                },
                {
                  url: "https://www.linkedin.com/in/anuragbhattacharjee/",
                  alts: [],
                  httpStatus: 999,
                },
                {
                  url:
                    "https://www.youtube.com/playlist?list=PL0zVEGEvSaeEd9hlmCXrk5yUyqUag-n84",
                  alts: [],
                  httpStatus: 200,
                },
                {
                  url:
                    "https://medium.com/front-end-weekly/immutability-in-array-of-objects-using-map-method-dd61584c7188",
                  alts: [
                    { type: 2, url: "medium://p/dd61584c7188" },
                    { type: 3, url: "medium://p/dd61584c7188" },
                  ],
                  httpStatus: 200,
                },
                {
                  url:
                    "https://medium.com/javascript-scene/reduce-composing-software-fe22f0c39a1d",
                  alts: [
                    { type: 3, url: "medium://p/fe22f0c39a1d" },
                    { type: 2, url: "medium://p/fe22f0c39a1d" },
                  ],
                  httpStatus: 200,
                },
                {
                  url:
                    "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/filter",
                  alts: [],
                  httpStatus: 200,
                },
                {
                  url:
                    "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/map",
                  alts: [],
                  httpStatus: 200,
                },
                {
                  url:
                    "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/Reduce",
                  alts: [],
                  httpStatus: 200,
                },
              ],
              version: "0.3",
              generatedAt: 1593520714144,
            },
            isLockedPreviewOnly: false,
            metaDescription: "",
            totalClapCount: 61,
            sectionCount: 6,
            readingList: 0,
            topics: [
              {
                topicId: "63c6f1f93ee",
                slug: "javascript",
                createdAt: 1493945704961,
                deletedAt: 0,
                image: {
                  id: "1*5UyyDJ1ESD11yQNd1UVAaQ@2x.jpeg",
                  originalWidth: 5472,
                  originalHeight: 3648,
                },
                name: "Javascript",
                description: "An ode to code.",
                relatedTopics: [],
                visibility: 1,
                relatedTags: [],
                relatedTopicIds: [],
                seoTitle: "Javascript News and Articles — Medium",
                type: "Topic",
              },
            ],
          },
          coverless: true,
          slug: "map-filter-reduce",
          translationSourcePostId: "",
          translationSourceCreatorId: "",
          isApprovedTranslation: false,
          inResponseToPostId: "",
          inResponseToRemovedAt: 0,
          isTitleSynthesized: false,
          allowResponses: true,
          importedUrl: "",
          importedPublishedAt: 0,
          visibility: 0,
          uniqueSlug: "map-filter-reduce-555b765f2645",
          previewContent: {
            bodyModel: {
              paragraphs: [
                {
                  name: "previewImage",
                  type: 4,
                  text: "",
                  layout: 10,
                  metadata: {
                    id: "1*d8YlI_rZ9RiGEQmnBjfbSQ.jpeg",
                    originalWidth: 1023,
                    originalHeight: 771,
                    isFeatured: true,
                  },
                },
                {
                  name: "090c",
                  type: 3,
                  text: "Map!! Filter!! Reduce !!",
                  markups: [],
                  alignment: 1,
                },
                {
                  name: "6087",
                  type: 6,
                  text:
                    "Dev1 saw a strange JavaScript function & asked, “What is this?”.\nDev2 responded, “I don’t know. I would’ve called…",
                  markups: [
                    { type: 1, start: 0, end: 4 },
                    { type: 1, start: 65, end: 69 },
                  ],
                  alignment: 1,
                },
              ],
              sections: [{ startIndex: 0 }],
            },
            isFullContent: false,
            subtitle: "",
          },
          license: 0,
          inResponseToMediaResourceId: "",
          canonicalUrl: "",
          approvedHomeCollectionId: "",
          newsletterId: "",
          webCanonicalUrl: "",
          mediumUrl: "",
          migrationId: "",
          notifyFollowers: true,
          notifyTwitter: false,
          notifyFacebook: false,
          responseHiddenOnParentPostAt: 0,
          isSeries: false,
          isSubscriptionLocked: false,
          seriesLastAppendedAt: 0,
          audioVersionDurationSec: 0,
          sequenceId: "",
          isEligibleForRevenue: false,
          isBlockedFromHightower: false,
          deletedAt: 0,
          lockedPostSource: 0,
          hightowerMinimumGuaranteeStartsAt: 0,
          hightowerMinimumGuaranteeEndsAt: 0,
          featureLockRequestAcceptedAt: 0,
          mongerRequestType: 1,
          layerCake: 0,
          socialTitle: "",
          socialDek: "",
          editorialPreviewTitle: "",
          editorialPreviewDek: "",
          curationEligibleAt: 1554121930889,
          isProxyPost: false,
          proxyPostFaviconUrl: "",
          proxyPostProviderName: "",
          proxyPostType: 0,
          isSuspended: false,
          isLimitedState: false,
          seoTitle: "",
          previewContent2: {
            bodyModel: {
              paragraphs: [
                {
                  name: "090c",
                  type: 3,
                  text: "Map!! Filter!! Reduce !!",
                  markups: [],
                },
                {
                  name: "6087",
                  type: 6,
                  text:
                    "Dev1 saw a strange JavaScript function & asked, “What is this?”.\nDev2 responded, “I don’t know. I would’ve called you, but I was in a bind”",
                  markups: [
                    { type: 1, start: 0, end: 4 },
                    { type: 1, start: 65, end: 69 },
                  ],
                },
                {
                  name: "4377",
                  type: 1,
                  text:
                    "That was just a JS dev joke that I copied from somewhere without filtering. I didn’t use a filter that is the reason this…",
                  markups: [],
                },
              ],
              sections: [{ startIndex: 0 }],
            },
            isFullContent: false,
            subtitle: "",
          },
          cardType: 0,
          isDistributionAlertDismissed: false,
          isShortform: false,
          shortformType: 0,
          responsesLocked: false,
          isLockedResponse: false,
          type: "Post",
        },
      },
      Collection: {
        cf8fcf569422: {
          id: "cf8fcf569422",
          name: "Voice Tech Podcast",
          slug: "voice-tech-podcast",
          tags: [
            "VOICE ASSISTANT",
            "CHATBOT DESIGN",
            "ALEXA",
            "CHATBOTS",
            "NATURALLANGUAGEPROCESSING",
          ],
          creatorId: "8822a43bb018",
          description:
            "Voice technology interviews & articles. Learn from the experts.",
          shortDescription: "Voice technology interviews & articles.",
          image: {
            imageId: "1*QDgZwaQz7QKCl5AzWs5LCw.png",
            filter: "",
            backgroundSize: "",
            originalWidth: 60,
            originalHeight: 60,
            strategy: "resample",
            height: 0,
            width: 0,
          },
          metadata: { followerCount: 564, activeAt: 1595937836149 },
          virtuals: {
            permissions: {
              canPublish: false,
              canPublishAll: false,
              canRepublish: true,
              canRemove: true,
              canManageAll: false,
              canSubmit: true,
              canEditPosts: false,
              canAddWriters: false,
              canViewStats: false,
              canSendNewsletter: false,
              canViewLockedPosts: true,
              canViewCloaked: true,
              canEditOwnPosts: true,
              canBeAssignedAuthor: true,
              canEnrollInHightower: false,
              canLockPostsForMediumMembers: false,
              canLockOwnPostsForMediumMembers: false,
              canViewNewsletterV2Stats: false,
              canCreateNewsletterV3: false,
            },
            isSubscribed: false,
            isEnrolledInHightower: false,
            isEligibleForHightower: false,
            isSubscribedToCollectionEmails: false,
            isMuted: false,
            canToggleEmail: false,
          },
          logo: {
            imageId: "1*sZtwaJWT6vyxEn8cAQ_2Jw.png",
            filter: "",
            backgroundSize: "",
            originalWidth: 1124,
            originalHeight: 352,
            strategy: "resample",
            height: 0,
            width: 0,
          },
          twitterUsername: "VoiceTechCarl",
          facebookPageName: "voicetechpod",
          publicEmail: "editorial@voicetechpodcast.com",
          collectionMastheadId: "2ac1442654b7",
          sections: [
            {
              type: 2,
              collectionHeaderMetadata: {
                backgroundImage: {
                  id: "1*Rp0Hw4rcBq00n1b2Pe5BfA.jpeg",
                  originalWidth: 1124,
                  originalHeight: 352,
                  focusPercentX: 53.214285714285715,
                  focusPercentY: 7.954545454545454,
                },
                logoImage: {
                  id: "1*iB5tWOD0zDhhHwtM-MOUZQ@2x.jpeg",
                  originalWidth: 512,
                  originalHeight: 512,
                  alt: "Voice Tech Podcast",
                },
                alignment: 2,
                layout: 5,
              },
            },
            {
              type: 1,
              postListMetadata: {
                source: 4,
                layout: 4,
                number: 5,
                postIds: [],
                tagSlug: "Voice Assistant",
                sectionHeader: "Voice Assistants",
              },
            },
            {
              type: 3,
              promoMetadata: { sectionHeader: "", promoId: "43504714594a" },
            },
            {
              type: 1,
              postListMetadata: {
                source: 4,
                layout: 4,
                number: 4,
                postIds: [],
                tagSlug: "Chatbots",
                sectionHeader: "Chatbots",
              },
            },
            {
              type: 3,
              promoMetadata: { sectionHeader: "", promoId: "2b581c8fd7ae" },
            },
            {
              type: 1,
              postListMetadata: {
                source: 4,
                layout: 4,
                number: 4,
                postIds: [],
                tagSlug: "Podcast",
                sectionHeader: "Podcast Episodes",
              },
            },
            {
              type: 3,
              promoMetadata: { sectionHeader: "", promoId: "2b2381c9aea5" },
            },
            {
              type: 1,
              postListMetadata: {
                source: 4,
                layout: 4,
                number: 5,
                postIds: [],
                tagSlug: "Chatbot Design",
                sectionHeader: "Design & Development",
              },
            },
            {
              type: 3,
              promoMetadata: { sectionHeader: "", promoId: "c91cc98e1ff8" },
            },
            {
              type: 1,
              postListMetadata: {
                source: 4,
                layout: 4,
                number: 4,
                postIds: [],
                tagSlug: "Naturallanguageprocessing",
                sectionHeader: "Voice AI & NLProc",
              },
            },
            {
              type: 3,
              promoMetadata: { sectionHeader: "", promoId: "3afc4c9ef42" },
            },
            {
              type: 1,
              postListMetadata: {
                source: 1,
                layout: 4,
                number: 8,
                postIds: [],
                sectionHeader: "More articles",
              },
            },
            {
              type: 1,
              postListMetadata: {
                source: 1,
                layout: 6,
                number: 1,
                postIds: [],
              },
            },
            {
              type: 3,
              promoMetadata: { sectionHeader: "", promoId: "6a87550ae6c8" },
            },
            {
              type: 3,
              promoMetadata: { sectionHeader: "", promoId: "53632d96c04f" },
            },
          ],
          tintColor: "#FFFFFFFF",
          lightText: false,
          favicon: {
            imageId: "",
            filter: "",
            backgroundSize: "",
            originalWidth: 0,
            originalHeight: 0,
            strategy: "resample",
            height: 0,
            width: 0,
          },
          colorPalette: {
            defaultBackgroundSpectrum: {
              colorPoints: [
                { color: "#FF848585", point: 0 },
                { color: "#FF7B7B7B", point: 0.1 },
                { color: "#FF717272", point: 0.2 },
                { color: "#FF686868", point: 0.3 },
                { color: "#FF5E5E5E", point: 0.4 },
                { color: "#FF545454", point: 0.5 },
                { color: "#FF494A4A", point: 0.6 },
                { color: "#FF3F3F3F", point: 0.7 },
                { color: "#FF333333", point: 0.8 },
                { color: "#FF272727", point: 0.9 },
                { color: "#FF1A1A1A", point: 1 },
              ],
              backgroundColor: "#FFFFFFFF",
            },
            tintBackgroundSpectrum: {
              colorPoints: [
                { color: "#FFFFFFFF", point: 0 },
                { color: "#FFEEEEEE", point: 0.1 },
                { color: "#FFDDDDDD", point: 0.2 },
                { color: "#FFCCCCCC", point: 0.3 },
                { color: "#FFBABABB", point: 0.4 },
                { color: "#FFA8A8A8", point: 0.5 },
                { color: "#FF959696", point: 0.6 },
                { color: "#FF818282", point: 0.7 },
                { color: "#FF6D6E6E", point: 0.8 },
                { color: "#FF575959", point: 0.9 },
                { color: "#FF404242", point: 1 },
              ],
              backgroundColor: "#FFFFFFFF",
            },
            highlightSpectrum: {
              colorPoints: [
                { color: "#FFF4F2F2", point: 0 },
                { color: "#FFF2F0F0", point: 0.1 },
                { color: "#FFF0EEEE", point: 0.2 },
                { color: "#FFEEECEC", point: 0.3 },
                { color: "#FFECEBEA", point: 0.4 },
                { color: "#FFEAE9E8", point: 0.5 },
                { color: "#FFE8E7E7", point: 0.6 },
                { color: "#FFE6E5E5", point: 0.7 },
                { color: "#FFE4E3E3", point: 0.8 },
                { color: "#FFE2E1E1", point: 0.9 },
                { color: "#FFE0DFDF", point: 1 },
              ],
              backgroundColor: "#FFFFFFFF",
            },
          },
          navItems: [
            {
              type: 4,
              title: "Voice Assistants",
              url:
                "https://medium.com/voice-tech-podcast/voice-assistants/home",
              topicId: "d576b2426a88",
              source: "topicId",
            },
            {
              type: 4,
              title: "Chatbots",
              url: "https://medium.com/voice-tech-podcast/chatbots/home",
              topicId: "5e7437ded542",
              source: "topicId",
            },
            {
              type: 4,
              title: "Podcasts",
              url: "https://medium.com/voice-tech-podcast/episodes/home",
              topicId: "fdae3ab2f24c",
              source: "topicId",
            },
            {
              type: 4,
              title: "Design & Dev",
              url:
                "https://medium.com/voice-tech-podcast/design-development/home",
              topicId: "26811675c26d",
              source: "topicId",
            },
            {
              type: 4,
              title: "AI & NLProc",
              url:
                "https://medium.com/voice-tech-podcast/machine-learning/home",
              topicId: "9975b02aef39",
              source: "topicId",
            },
            {
              type: 6,
              title: "Authors",
              url: "https://medium.com/voice-tech-podcast/about",
            },
            {
              type: 3,
              title: "Newsletter",
              url: "https://voicetechpodcast.com/newsletter",
            },
          ],
          colorBehavior: 1,
          instantArticlesState: 0,
          acceleratedMobilePagesState: 0,
          ampLogo: {
            imageId: "",
            filter: "",
            backgroundSize: "",
            originalWidth: 0,
            originalHeight: 0,
            strategy: "resample",
            height: 0,
            width: 0,
          },
          header: {
            backgroundImage: {
              id: "1*Rp0Hw4rcBq00n1b2Pe5BfA.jpeg",
              originalWidth: 1124,
              originalHeight: 352,
              focusPercentX: 53.214285714285715,
              focusPercentY: 7.954545454545454,
            },
            logoImage: {
              id: "1*iB5tWOD0zDhhHwtM-MOUZQ@2x.jpeg",
              originalWidth: 512,
              originalHeight: 512,
              alt: "Voice Tech Podcast",
            },
            alignment: 2,
            layout: 5,
          },
          subscriberCount: 564,
          tagline: "Voice technology interviews & articles.",
          isOptedIntoAurora: false,
          isCurationAllowedByDefault: false,
          type: "Collection",
        },
        "5e5bef33608a": {
          id: "5e5bef33608a",
          name: "Becoming Human: Artificial Intelligence Magazine",
          slug: "becoming-human",
          tags: [
            "ARTIFICIAL INTELLIGENCE",
            "DEEP LEARNING",
            "MACHINE LEARNING",
            "AI",
            "DATA SCIENCE",
          ],
          creatorId: "67c8653ca7e2",
          description:
            "Latest News, Info and Tutorials on Artificial Intelligence, Machine Learning, Deep Learning, Big Data and what it means for Humanity.",
          shortDescription:
            "Latest News, Info and Tutorials on Artificial Intelligence…",
          image: {
            imageId: "1*TmZdlak7DM_hrwAFWf2jvA.png",
            filter: "",
            backgroundSize: "",
            originalWidth: 500,
            originalHeight: 500,
            strategy: "resample",
            height: 0,
            width: 0,
          },
          metadata: { followerCount: 33227, activeAt: 1595889328300 },
          virtuals: {
            permissions: {
              canPublish: false,
              canPublishAll: false,
              canRepublish: true,
              canRemove: true,
              canManageAll: false,
              canSubmit: true,
              canEditPosts: false,
              canAddWriters: false,
              canViewStats: false,
              canSendNewsletter: false,
              canViewLockedPosts: true,
              canViewCloaked: true,
              canEditOwnPosts: true,
              canBeAssignedAuthor: true,
              canEnrollInHightower: false,
              canLockPostsForMediumMembers: false,
              canLockOwnPostsForMediumMembers: false,
              canViewNewsletterV2Stats: false,
              canCreateNewsletterV3: false,
            },
            isSubscribed: false,
            isEnrolledInHightower: false,
            isEligibleForHightower: false,
            isSubscribedToCollectionEmails: false,
            isMuted: false,
            canToggleEmail: false,
          },
          logo: {
            imageId: "1*1fYpRTTpKQNa0zuEPe3itg.png",
            filter: "",
            backgroundSize: "",
            originalWidth: 610,
            originalHeight: 250,
            strategy: "resample",
            height: 0,
            width: 0,
          },
          twitterUsername: "BecomingHumanAI",
          facebookPageName: "BecomingHumanAI",
          publicEmail: "team@chatbotslife.com",
          domain: "becominghuman.ai",
          sections: [
            {
              type: 2,
              collectionHeaderMetadata: {
                title: "Becoming Human",
                description:
                  "Exploring Artificial Intelligence & What it Means to be Human",
                backgroundImage: {},
                logoImage: {
                  id: "1*1fYpRTTpKQNa0zuEPe3itg@2x.png",
                  originalWidth: 610,
                  originalHeight: 250,
                  alt: "Becoming Human: Artificial Intelligence Magazine",
                },
                alignment: 1,
                layout: 6,
              },
            },
            {
              type: 1,
              postListMetadata: {
                source: 3,
                layout: 4,
                number: 1,
                postIds: ["f22dc900d2d7"],
              },
            },
            {
              type: 1,
              postListMetadata: {
                source: 4,
                layout: 4,
                number: 5,
                postIds: [],
                tagSlug: "Artificial Intelligence",
              },
            },
            {
              type: 3,
              promoMetadata: {
                sectionHeader: "AI Jobs",
                promoId: "327650014de1",
              },
            },
            {
              type: 1,
              postListMetadata: {
                source: 1,
                layout: 4,
                number: 3,
                postIds: [],
                sectionHeader: "Latest",
              },
            },
            {
              type: 1,
              postListMetadata: {
                source: 2,
                layout: 3,
                number: 7,
                postIds: [],
                sectionHeader: "Trending",
              },
            },
            {
              type: 1,
              postListMetadata: {
                source: 1,
                layout: 7,
                number: 7,
                postIds: [],
                sectionHeader: "Latest",
              },
            },
            {
              type: 3,
              promoMetadata: {
                sectionHeader: "AI Conference",
                promoId: "81d4c907d798",
              },
            },
            {
              type: 1,
              postListMetadata: {
                source: 4,
                layout: 4,
                number: 7,
                postIds: [],
                tagSlug: "Tutorial",
                sectionHeader: "Tutorials",
              },
            },
            {
              type: 3,
              promoMetadata: { sectionHeader: "", promoId: "da7c0a40b868" },
            },
          ],
          tintColor: "#FF000000",
          lightText: true,
          favicon: {
            imageId: "1*BKQogJIIAxdNOm1L-gf-MA.png",
            filter: "",
            backgroundSize: "",
            originalWidth: 500,
            originalHeight: 500,
            strategy: "resample",
            height: 0,
            width: 0,
          },
          colorPalette: {
            defaultBackgroundSpectrum: {
              colorPoints: [
                { color: "#FF868484", point: 0 },
                { color: "#FF7C7B7A", point: 0.1 },
                { color: "#FF737171", point: 0.2 },
                { color: "#FF696867", point: 0.3 },
                { color: "#FF5F5E5E", point: 0.4 },
                { color: "#FF555454", point: 0.5 },
                { color: "#FF4A4949", point: 0.6 },
                { color: "#FF3F3E3E", point: 0.7 },
                { color: "#FF343333", point: 0.8 },
                { color: "#FF272727", point: 0.9 },
                { color: "#FF1A1A1A", point: 1 },
              ],
              backgroundColor: "#FFFFFFFF",
            },
            tintBackgroundSpectrum: {
              colorPoints: [
                { color: "#FF000000", point: 0 },
                { color: "#FF1E1D1D", point: 0.1 },
                { color: "#FF3C3B3B", point: 0.2 },
                { color: "#FF565555", point: 0.3 },
                { color: "#FF6F6D6D", point: 0.4 },
                { color: "#FF868484", point: 0.5 },
                { color: "#FF9C9A99", point: 0.6 },
                { color: "#FFB1AEAE", point: 0.7 },
                { color: "#FFC5C3C2", point: 0.8 },
                { color: "#FFD9D6D6", point: 0.9 },
                { color: "#FFECE9E9", point: 1 },
              ],
              backgroundColor: "#FF000000",
            },
            highlightSpectrum: {
              colorPoints: [
                { color: "#FFF5F2F1", point: 0 },
                { color: "#FFF3F0EF", point: 0.1 },
                { color: "#FFF1EEED", point: 0.2 },
                { color: "#FFEFECEC", point: 0.3 },
                { color: "#FFEDEAEA", point: 0.4 },
                { color: "#FFEBE8E8", point: 0.5 },
                { color: "#FFE9E6E6", point: 0.6 },
                { color: "#FFE7E5E4", point: 0.7 },
                { color: "#FFE5E3E2", point: 0.8 },
                { color: "#FFE4E1E0", point: 0.9 },
                { color: "#FFE2DFDE", point: 1 },
              ],
              backgroundColor: "#FFFFFFFF",
            },
          },
          navItems: [
            {
              type: 2,
              title: "🔥 CONSULTING",
              postId: "af09386dc05d",
              url:
                "https://becominghuman.ai/artificial-intelligence-software-developers-af09386dc05d",
              source: "postId",
            },
            {
              type: 1,
              title: "🎓 TUTORIALS",
              tagSlug: "tutorial",
              url: "https://becominghuman.ai/tagged/tutorial",
              source: "tagSlug",
            },
            {
              type: 2,
              title: "✍️ SUBMIT AN ARTICLE",
              postId: "48270209de63",
              url: "https://becominghuman.ai/write-for-us-48270209de63",
              source: "postId",
            },
            {
              type: 2,
              title: "😎 COMMUNITIES",
              postId: "c305f28e674c",
              url:
                "https://becominghuman.ai/artificial-intelligence-communities-c305f28e674c",
              source: "postId",
            },
            {
              type: 3,
              title: "🖥️ AI JOBS BOARD",
              url: "https://aijobsboard.com/",
            },
          ],
          colorBehavior: 2,
          instantArticlesState: 0,
          acceleratedMobilePagesState: 0,
          googleAnalyticsId: "UA-109504964-1",
          ampLogo: {
            imageId: "",
            filter: "",
            backgroundSize: "",
            originalWidth: 0,
            originalHeight: 0,
            strategy: "resample",
            height: 0,
            width: 0,
          },
          header: {
            title: "Becoming Human",
            description:
              "Exploring Artificial Intelligence & What it Means to be Human",
            backgroundImage: {},
            logoImage: {
              id: "1*1fYpRTTpKQNa0zuEPe3itg@2x.png",
              originalWidth: 610,
              originalHeight: 250,
              alt: "Becoming Human: Artificial Intelligence Magazine",
            },
            alignment: 1,
            layout: 6,
          },
          paidForDomainAt: 1494467108243,
          subscriberCount: 33227,
          tagline:
            "Latest News, Info and Tutorials on Artificial Intelligence…",
          isOptedIntoAurora: false,
          newsletterV3: {
            newsletterV3Id: "c82dd382f76a",
            type: 1,
            name: "Latest from Becoming Human AI",
            description: "Watch AI & Bot Conference for Free",
            collectionId: "5e5bef33608a",
            newsletterSlug: "latest-from-becoming-human-ai",
            isSubscribed: false,
            showPromo: true,
          },
          isCurationAllowedByDefault: false,
          type: "Collection",
        },
        "7219b4dc6c4c": {
          id: "7219b4dc6c4c",
          name: "Analytics Vidhya",
          slug: "analytics-vidhya",
          tags: [
            "MACHINE LEARNING",
            "ARTIFICIAL INTELLIGENCE",
            "DEEP LEARNING",
            "DATA SCIENCE",
            "PYTHON",
          ],
          creatorId: "c7c686fcd4b",
          description:
            "Analytics Vidhya is a community of Analytics and Data Science professionals. We are building the next-gen data science ecosystem https://www.analyticsvidhya.com",
          shortDescription:
            "Analytics Vidhya is a community of Analytics and Data…",
          image: {
            imageId: "1*miCA9MEw8TjpXyR0xY1w-A.png",
            filter: "",
            backgroundSize: "",
            originalWidth: 64,
            originalHeight: 64,
            strategy: "resample",
            height: 0,
            width: 0,
          },
          metadata: { followerCount: 28352, activeAt: 1595943668646 },
          virtuals: {
            permissions: {
              canPublish: false,
              canPublishAll: false,
              canRepublish: true,
              canRemove: true,
              canManageAll: false,
              canSubmit: true,
              canEditPosts: false,
              canAddWriters: false,
              canViewStats: false,
              canSendNewsletter: false,
              canViewLockedPosts: true,
              canViewCloaked: true,
              canEditOwnPosts: true,
              canBeAssignedAuthor: true,
              canEnrollInHightower: false,
              canLockPostsForMediumMembers: false,
              canLockOwnPostsForMediumMembers: false,
              canViewNewsletterV2Stats: false,
              canCreateNewsletterV3: false,
            },
            isSubscribed: true,
            isEnrolledInHightower: false,
            isEligibleForHightower: false,
            isSubscribedToCollectionEmails: false,
            isMuted: false,
            canToggleEmail: false,
          },
          logo: {
            imageId: "1*cK8jYS5H7rDYhb0vZkW4NA.png",
            filter: "",
            backgroundSize: "",
            originalWidth: 364,
            originalHeight: 90,
            strategy: "resample",
            height: 0,
            width: 0,
          },
          twitterUsername: "analyticsvidhya",
          facebookPageName: "analyticsvidhya",
          publicEmail: "medium@analyticsvidhya.com",
          sections: [
            {
              type: 2,
              collectionHeaderMetadata: {
                backgroundImage: {},
                logoImage: {
                  id: "1*cK8jYS5H7rDYhb0vZkW4NA@2x.png",
                  originalWidth: 364,
                  originalHeight: 90,
                  alt: "Analytics Vidhya",
                },
                alignment: 2,
                layout: 4,
              },
            },
            {
              type: 1,
              postListMetadata: {
                source: 3,
                layout: 4,
                number: 4,
                postIds: [
                  "69703e96dbdb",
                  "f31b12ca8131",
                  "dcbc0bab3801",
                  "921d7e413a3a",
                ],
              },
            },
            {
              type: 1,
              postListMetadata: {
                source: 1,
                layout: 4,
                number: 5,
                postIds: [],
                sectionHeader: "Latest Stories",
              },
            },
            {
              type: 1,
              postListMetadata: {
                source: 4,
                layout: 4,
                number: 3,
                postIds: [],
                tagSlug: "Editors Pick",
                sectionHeader: "Editor's Pick",
              },
            },
            {
              type: 1,
              postListMetadata: {
                source: 4,
                layout: 4,
                number: 3,
                postIds: [],
                tagSlug: "Machine Learning",
                sectionHeader: "Machine Learning",
              },
            },
            {
              type: 1,
              postListMetadata: {
                source: 4,
                layout: 4,
                number: 3,
                postIds: [],
                tagSlug: "Deep Learning",
                sectionHeader: "Deep Learning",
              },
            },
            {
              type: 1,
              postListMetadata: {
                source: 4,
                layout: 4,
                number: 3,
                postIds: [],
                tagSlug: "Programming",
                sectionHeader: "Programming",
              },
            },
          ],
          favicon: {
            imageId: "",
            filter: "",
            backgroundSize: "",
            originalWidth: 0,
            originalHeight: 0,
            strategy: "resample",
            height: 0,
            width: 0,
          },
          colorPalette: {
            defaultBackgroundSpectrum: {
              colorPoints: [
                { color: "#FF02B875", point: 0 },
                { color: "#FF00AB6B", point: 0.1 },
                { color: "#FF1C9963", point: 0.2 },
                { color: "#FF092E20", point: 1 },
              ],
              backgroundColor: "#FFFFFFFF",
            },
            highlightSpectrum: {
              colorPoints: [
                { color: "#FFFFFFFF", point: 0 },
                { color: "#FFE9FDF0", point: 0.1 },
                { color: "#FFE2FAEE", point: 0.2 },
                { color: "#FFADFFCF", point: 0.6 },
                { color: "#FF7DFFB3", point: 1 },
              ],
              backgroundColor: "#FFFFFFFF",
            },
          },
          navItems: [
            {
              type: 6,
              title: "About Us",
              url: "https://medium.com/analytics-vidhya/about",
            },
            {
              type: 1,
              title: "Deep Learning",
              tagSlug: "deep-learning",
              url: "https://medium.com/analytics-vidhya/tagged/deep-learning",
              source: "tagSlug",
            },
            {
              type: 1,
              title: "Machine Learning",
              tagSlug: "machine-learning",
              url:
                "https://medium.com/analytics-vidhya/tagged/machine-learning",
              source: "tagSlug",
            },
            {
              type: 1,
              title: "Hackathon",
              tagSlug: "hackathons",
              url: "https://medium.com/analytics-vidhya/tagged/hackathons",
              source: "tagSlug",
            },
            {
              type: 4,
              title: "Contribute",
              url: "https://medium.com/analytics-vidhya/contribute/home",
              topicId: "aaf339499270",
              source: "topicId",
            },
            {
              type: 3,
              title: "Free Courses",
              url:
                "https://courses.analyticsvidhya.com/collections?category=free",
            },
          ],
          colorBehavior: 1,
          instantArticlesState: 0,
          acceleratedMobilePagesState: 0,
          ampLogo: {
            imageId: "",
            filter: "",
            backgroundSize: "",
            originalWidth: 0,
            originalHeight: 0,
            strategy: "resample",
            height: 0,
            width: 0,
          },
          header: {
            backgroundImage: {},
            logoImage: {
              id: "1*cK8jYS5H7rDYhb0vZkW4NA@2x.png",
              originalWidth: 364,
              originalHeight: 90,
              alt: "Analytics Vidhya",
            },
            alignment: 2,
            layout: 4,
          },
          subscriberCount: 28352,
          tagline: "Analytics Vidhya is a community of Analytics and Data…",
          isOptedIntoAurora: false,
          isCurationAllowedByDefault: false,
          type: "Collection",
        },
      },
      Social: {
        "9a619946f362": {
          userId: "9a619946f362",
          targetUserId: "9a619946f362",
          type: "Social",
        },
      },
      SocialStats: {
        "9a619946f362": {
          userId: "9a619946f362",
          usersFollowedCount: 125,
          usersFollowedByCount: 65,
          type: "SocialStats",
        },
      },
    },
    paging: {
      path: "https://medium.com/_/api/users/9a619946f362/profile/stream",
      next: {
        limit: 10,
        to: "1546852629626",
        source: "latest",
        ignoredIds: [],
        page: 1,
      },
    },
  },
  v: 3,
  b: "41759-387dd68",
};
